import { Typography, Link } from '@okta/odyssey-react-mui';
import { Box, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FooterNavigation = () => {
  const { t } = useTranslation(['core']);

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center !important',
        marginTop: '1rem',
      }}
    >
      <Box display="flex" gap={'2rem'} className="footer-links">
        <Typography variant="support">{t('nav-footer-copyright')}</Typography>
        <Link variant="monochrome" href="https://www.okta.com/privacy-policy/">
          {t('nav-footer-policy-label')}
        </Link>
        <Link
          variant="monochrome"
          href="https://www.okta.com/terms-of-service/"
        >
          {t('nav-footer-terms-label')}
        </Link>
        <button className="ot-sdk-show-settings MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-dr9k8r-MuiTypography-root-MuiLink-root buttonLink">
          {t('nav-footer-cookie-label')}
        </button>
        <Link
          variant="monochrome"
          href="https://www.okta.com/your-privacy-choices/"
        >
          {t('nav-footer-privacy-label')}
        </Link>
      </Box>
    </Container>
  );
};

export default FooterNavigation;
