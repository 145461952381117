import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import OpportunityPicker from '../components/OpportunityPicker';
import { useOpportunityContext } from '../context';
import { useFlags, useFlagsmithLoading } from 'flagsmith/react';
import NavigationButton from '../components/NavigationButton';
import { generateDemoName } from '../util/GenerateDemoNames';
import BrandingPrompt from '../components/template/BrandingPrompt';
import ADPrompt from '../components/template/ADPrompts';
import { useDemoContext } from '../context';
import { useInterval } from '../useInterval';
import Oktaprise from '../components/template/Oktaprise';
import Productivity from '../components/template/ProductivityPrompt';
import StorytimePrompt from '../components/template/StorytimePrompt';
import FGAPrompt from '../components/template/FGAPrompt';
import Container from '../components/ui/Container/Container';
import Header from '../components/ui/Header/Header';
import Divider from '../components/ui/Divider/Divider';
import {
  CircularProgress,
  Button,
  Surface,
  Tooltip,
  TextField,
  Form,
  Callout,
} from '@okta/odyssey-react-mui';
import {
  InformationCircleFilledIcon,
  RefreshIcon,
} from '@okta/odyssey-react-mui/icons';
import { useServiceContext } from '../context';

const TemplateDeploy = () => {
  let params = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const demoContext = useDemoContext();
  const { opportunities } = useOpportunityContext();
  const flags = useFlags([
    'one_click_wic',
    'one_click_cic',
    'fga_smarthub',
    'crowdstrike',
    'pitchbook_software_rationalization',
    'opportunity_linking',
    'oig_enablement',
    'itp_enablement',
  ]);
  const flagsmithState = useFlagsmithLoading();

  const [idpPollFrequency, setIdpPollFrequency] = useState(null);
  const [demoPollFrequency, setDemoPollFrequency] = useState(null);

  const [stage, setStage] = useState('invite');
  const [steps, setSteps] = useState([]);
  const [stepsTaken, setStepsTaken] = useState([]);

  const [oppLinked, setOppLinked] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');

  const [template, setTemplate] = useState({});
  const [name, setName] = useState(generateDemoName());
  const [nameError, setNameError] = useState();
  const [demo, setDemo] = useState();
  const [idp, setIdp] = useState();
  const { demoService, identityProviderService, componentInstanceService } =
    useServiceContext();

  useEffect(() => {
    if (
      isAuthenticated &&
      !flagsmithState.isLoading &&
      !flagsmithState.isFetching
    ) {
      if (!params.template) {
        navigate('/');
        return;
      }
      var templateName = params.template.replaceAll('-', '_');
      var templateConfig = flags[templateName];
      if (!templateConfig || !templateConfig.enabled || !templateConfig.value) {
        navigate('/');
      } else {
        const template = JSON.parse(templateConfig.value);
        setTemplate(template);
      }
    }
  }, [
    navigate,
    isAuthenticated,
    flags,
    params.template,
    flagsmithState.isLoading,
    flagsmithState.isFetching,
  ]);

  function navigateToDemo() {
    navigate('/demo/' + name, {
      replace: true,
      state: { demo: demo, idp: idp },
    });
  }

  async function completeAction(action) {
    var newSteps = steps;
    var resolved = newSteps.shift();
    if (resolved === undefined) {
      newSteps = [];
    }
    if (action?.steps) {
      for (let index = action.steps.length - 1; index >= 0; index--) {
        const element = action.steps[index];
        newSteps.unshift(element);
      }
    }

    if (action) {
      setStage('deploy');
      await componentInstanceService.attachComponent(
        name,
        action.id,
        undefined,
        action.settings
      );
      var completedSteps = stepsTaken;
      completedSteps.push(action);
      setStepsTaken(completedSteps);
    }
    setDemoPollFrequency(2000);
    setSteps(newSteps);
  }

  function canProgress() {
    var requiredStepsComplete = true;
    stepsTaken.forEach((element) => {
      var step;
      switch (element.type) {
        case 'application': {
          step = demo?.app_instances?.find(
            (item) => item.componentId === element.id
          );
          break;
        }
        case 'resource': {
          step = demo?.resource_instances?.find(
            (item) => item.componentId === element.id
          );
          break;
        }
        default: {
          console.error('Unknown required object type: ' + element.type);
        }
      }
      if (step === undefined || (element.await && step.state !== 'active')) {
        requiredStepsComplete = false;
      }
    });
    return requiredStepsComplete;
  }

  function handleChange(event) {
    switch (event.target.id) {
      case 'name':
        setName(event.target.value);
        break;
      default:
        break;
    }
  }

  function validateForm() {
    let result = true;

    //test alphanumeric and hyphen, start & end cannot be hyphen
    const tenantPattern = '^(?!W|-)((?!admin|okta)[a-z-0-9]){3,63}$';
    const tenantRegex = new RegExp(`^${tenantPattern}$`);

    if (
      !name ||
      name.length < 3 ||
      name.length > 63 ||
      !tenantRegex.test(name)
    ) {
      setNameError(
        "Name must be between 3 and 63 characters and include only alphanumeric and hyphen. This must not contain the words 'admin' or 'okta'."
      );
      result = false;
    } else {
      setNameError();
    }
    return result;
  }

  async function linkOpp(event) {
    if (event.target.value !== 'noop') {
      try {
        await demoService.linkDemoToOpportunity(name, event.target.value);
      } catch (err) {
        console.error(err);
      }
    }
    setOppLinked(true);
  }

  async function submitForm() {
    if (validateForm()) {
      setStage('provision');
      setDisplayMessage('Creating your demo.');
      try {
        let identityProvider =
          await identityProviderService.createIdentityProvider(
            name,
            template.idpType,
            template.idpVariant
          );
        setIdp(identityProvider);
        setIdpPollFrequency(2000);

        let demo = await demoService.createDemo(
          name,
          'opportunity',
          idp.idp_id,
          template.label,
          template.name
        );
        demoContext.refreshContext();
        setDemo(demo);
        setDemoPollFrequency(2000);
        setSteps(template.steps);
      } catch (err) {
        setStage('error');
      }
    }
  }

  useInterval(async () => {
    if (idp) {
      let identityProvider = await identityProviderService.getIdentityProvider(
        idp.idp_id
      );
      setIdp(identityProvider);
    }
  }, idpPollFrequency);

  useInterval(async () => {
    if (demo) {
      try {
        let demo = await demoService.getDemo(name);
        if (idp.state === 'active') {
          if (demo.state === 'error') {
            setStage('error');
          }
          var progress = canProgress();
          if (progress && steps?.length > 0) {
            if (!steps[0].prompt) {
              completeAction(steps[0]);
            } else {
              setStage('step');
              setDemoPollFrequency(null);
            }
          } else if (steps?.length === 0 && progress) {
            navigateToDemo();
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, demoPollFrequency);

  //Monitor for IDP in ready state
  useEffect(() => {
    switch (idp?.state) {
      case 'error':
        setDisplayMessage('Waiting for Identity Cloud to be ready...');
        break;
      case 'queued':
        setDisplayMessage('Waiting in queue...');
        break;
      case 'deploying':
        setDisplayMessage('Launching Identity Cloud...');
        break;
      case 'active':
        setIdpPollFrequency(null);
        break;
      default:
        break;
    }
  }, [idp?.state]);

  function renderOppComponent() {
    return (
      <Container>
        {!oppLinked &&
          flags.opportunity_linking.enabled &&
          opportunities.length > 0 && (
            <Container>
              {/* <Segment basic> */}
              <Header>Link your opportunity</Header>
              <OpportunityPicker allowEdit={true} updateOpportunity={linkOpp} />
              {/* </Segment> */}
            </Container>
          )}
      </Container>
    );
  }

  function renderStep() {
    if (steps && steps.length >= 1) {
      if (steps[0].prompt) {
        switch (steps[0].prompt) {
          case 'branding':
            return (
              <BrandingPrompt
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          case 'ad':
            return (
              <ADPrompt
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          case 'oktaprise':
            return (
              <Oktaprise
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          case 'productivity':
            return (
              <Productivity
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          case 'storytime':
            return (
              <StorytimePrompt
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          case 'fga':
            return (
              <FGAPrompt
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          default:
            console.error('Unhandled prompt.');
            completeAction();
        }
      }
    }
  }

  function renderStage(stage) {
    switch (stage) {
      case 'invite':
        return (
          <Container>
            <Header className="contentHeader">Template: {template.name}</Header>
            <Divider className="noTopMargin" />
            <Container className="pre-line-text">
              {template.description}
            </Container>
            <Divider />
            <Form
              noValidate
              formActions={
                <>
                  {' '}
                  <Button
                    className="branded"
                    type="submit"
                    onClick={submitForm}
                    label="Create"
                  >
                    Create
                  </Button>
                </>
              }
            >
              <TextField
                id="name"
                endAdornment={
                  <Button
                    variant="floating"
                    onClick={() => {
                      setName(generateDemoName());
                    }}
                    startIcon={<RefreshIcon />}
                  ></Button>
                }
                label={
                  <label>
                    Demonstration name{' '}
                    <Tooltip
                      ariaType="label"
                      placement="top"
                      text={
                        'Enter a unique name for your demonstration. ' +
                        'This name will be visible in the identity provider tenant as well as some applications. '
                      }
                    >
                      <Button
                        startIcon={<InformationCircleFilledIcon />}
                        variant="floating"
                        className="buttonStyle"
                        style={{ padding: 0 }}
                      />
                    </Tooltip>
                  </label>
                }
                value={name}
                onChange={handleChange}
                errorMessage={nameError}
                onKeyUp={validateForm}
                style={{ textTransform: 'lowercase' }}
                required
              />
            </Form>
          </Container>
        );
      case 'provision':
        return (
          <Container>
            <Header className="contentHeader">Demonstration: {name}</Header>
            <Divider className="noTopMargin" />
            <Container textAlign="center">
              <div className="loadingContainer">
                <CircularProgress active inline variant="indeterminate" />
                <span>{displayMessage}</span>
              </div>
            </Container>
            {renderOppComponent()}
          </Container>
        );
      case 'step':
        return (
          <Container>
            <Header className="contentHeader">Demonstration: {name}</Header>
            <Divider className="noTopMargin" />
            {renderOppComponent()}
            {renderStep()}
          </Container>
        );
      case 'deploy':
        return (
          <Container>
            <Header className="contentHeader">Demonstration: {name}</Header>
            <Divider className="noTopMargin" />
            <Container textAlign="center">
              <div className="loadingContainer">
                <CircularProgress
                  active
                  inline
                  variant="indeterminate"
                  content={'Deploying template...'}
                />
                <span>Deploying template...</span>
              </div>
            </Container>
            {renderOppComponent()}
          </Container>
        );
      case 'error':
        return (
          <Container>
            <Header className="contentHeader">Demonstration: {name}</Header>
            <Divider className="noTopMargin" />
            <Container textAlign="center">
              <Callout
                role="alert"
                severity="error"
                id="templateDeployment"
                title="Something went wrong deploying template"
              >
                <p>
                  A component encountered an error whilst deploying your
                  template and deployment has stopped. Please view the logs for
                  details.
                </p>
                <p>
                  If the problem persists please reach out to the Demo
                  Engineering team.
                </p>
                <Divider hidden />
                <Button
                  className="branded"
                  type="submit"
                  onClick={() => navigateToDemo()}
                  label="Continue to demo"
                ></Button>
              </Callout>
            </Container>
          </Container>
        );
      default:
    }
    return;
  }

  if (flagsmithState.isLoading && !flagsmithState.isFetching) {
    return (
      <Container className="appComponent">
        <CircularProgress active inline />
      </Container>
    );
  }
  return (
    <Container className="appComponent">
      <NavigationButton destination="/" msg="Show my demos" />
      <Surface>{renderStage(stage)}</Surface>
    </Container>
  );
};

export default TemplateDeploy;
