import { TextField, Select, Button, Box } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { EditIcon } from '@okta/odyssey-react-mui/icons';

const EditableSelectField = ({ label, hint, value, onSave, options }) => {
  const { t } = useTranslation(['core', 'component', 'demo']);
  const [editedValue, setEditedValue] = useState(value);

  const [isEditMode, setIsEditMode] = useState(false);
  if (isEditMode) {
    return (
      <>
        <Select
          label={label}
          hint={hint}
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
          options={options}
        />
        <Box sx={{ maxWidth: '55ch' }}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              variant="floating"
              label={t('button-cancel-label', { ns: 'core' })}
              onClick={() => setIsEditMode(false)}
            />
            <Button
              label={t('button-save-label', { ns: 'core' })}
              onClick={() => {
                onSave(editedValue);
                setIsEditMode(false);
              }}
            />
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <TextField
        label={label}
        hint={hint}
        isReadOnly
        value={options.find((e) => e.value === value)?.text}
        endAdornment={
          <Button
            startIcon={<EditIcon />}
            size="small"
            variant="floating"
            onClick={() => {
              setEditedValue(value);
              setIsEditMode(true);
            }}
          />
        }
      />
    );
  }
};
export default EditableSelectField;
