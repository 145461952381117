import { Tag } from '@okta/odyssey-react-mui';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ComponentTag = ({ tag }) => {
  const { t } = useTranslation(['core', 'component', 'demo']);
  switch (tag) {
    case 'oig':
    case 'opa':
    case 'itp':
    case 'itsm':
    case 'oda':
    case 'workflows':
    case 'ud':
    case 'enterprise-conn':
    case 'access-gateway':
    case 'ama':
      return (
        <Tag
          label={t(`tag-${tag}`, { ns: 'component' })}
          size="small"
          icon={
            <Avatar
              alt="Okta"
              src="https://cdn.demo.okta.com/images/okta-icon.png"
              sx={{
                width: 30,
                height: 30,
                border: '2px solid white',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              }}
            />
          }
        />
      );
    case 'fga':
    case 'hri':
    case 'forms':
    case 'acul':
      return (
        <Tag
          label={t(`tag-${tag}`, { ns: 'component' })}
          size="small"
          icon={
            <Avatar
              alt="Auth0"
              src="https://cdn.demo.okta.com/images/okta/CIC/icon_black.png"
              sx={{
                width: 30,
                height: 30,
                border: '2px solid white',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              }}
            />
          }
        />
      );
    default:
      return <Tag label={t(`tag-${tag}`, { ns: 'component' })} size="small" />;
  }
};
export default ComponentTag;
