import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Dialog,
  Link,
  Surface,
  Typography,
  Stack,
} from '@okta/odyssey-react-mui';
import Skeleton from '@mui/material/Skeleton';
import Header from '../ui/Header/Header';
import CollaboratorList from '../collaboration/CollaboratorList';
import {
  ChatIcon,
  CheckIcon,
  CloseIcon,
  DocumentationIcon,
  VideoIcon,
} from '@okta/odyssey-react-mui/icons';
import { useDemoContext } from '../../context/DemoContext';
import DemoList from '../fields/DemoList';
import AttachComponentButton from '../ui/Button/AttachComponentButton';
import { useNavigate } from 'react-router-dom';
import Image from '../ui/Image/Image';
import flagsmith from 'flagsmith';
import { useServiceContext } from '../../context';
import { useTranslation } from 'react-i18next';

const LibraryComponentDetails = () => {
  const { id } = useParams();
  const [componentData, setComponentData] = useState(null);
  const [metricData, setMetricData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [supportedDemos, setSupportedDemos] = useState([]);
  const { demos, idps } = useDemoContext();
  const [targetDemo, setTargetDemo] = useState(null);
  const { componentInstanceService, componentService } = useServiceContext();

  const [attachDialogOpen, setAttachDialogOpen] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(['idp', 'component', 'demo', 'core']);

  useEffect(() => {
    setError(null);
    const fetchData = async () => {
      try {
        componentService.retrieveComponent(id).then((response) => {
          if (response) setComponentData(response);
          if (flagsmith.getTrait('connection') === 'employee') {
            componentService
              .retrieveComponentMetrics(id)
              .then((response) => {
                if (response) setMetricData(response);
              })
              .catch((error) => {
                setError(error);
              });
          }
        });
      } finally {
        setLoading(false);
      }
    };

    if (componentService) {
      fetchData();
    }
  }, [id, componentService]);

  useEffect(() => {
    if (componentData && demos && idps) {
      const supportingIdps = idps.filter((demo) =>
        componentData.dependsOn.idp?.types.includes(demo.type)
      );
      const supportingDemos = demos.filter((demo) =>
        supportingIdps.some((idp) => idp.idp_id === demo.idp_id)
      );
      setSupportedDemos(supportingDemos);
    }
  }, [componentData, demos, idps, id]);

  const attachComponent = async (id, instanceSettings) => {
    if (!targetDemo) return;
    try {
      setWaiting(true);
      await componentInstanceService.attachComponent(
        targetDemo,
        componentData.componentId,
        componentData.general.name,
        instanceSettings
      );
      setAttachDialogOpen(false);
      navigate('/demo/' + targetDemo);
    } catch (error) {
      setError(error);
      setWaiting(false);
    }
  };

  return (
    <>
      {componentData ? (
        <div>
          <div
            className="componentHeader"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Header>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Image
                  src={
                    componentData.general.logoUri
                      ? componentData.general.logoUri
                      : 'https://cdn.demo.okta.com/images/identicons-set-v2/mono/svg/icon-integrations-marketplace.svg'
                  }
                  alt={componentData.general.name}
                  size="small"
                  style={{ marginRight: '10px' }}
                />
                <div>
                  {componentData.general.name}
                  <span>
                    {componentData.general.category === 'managed' ? (
                      <Typography variant="subordinate">
                        Managed Component
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="subordinate">
                          Community Component
                        </Typography>
                        <CollaboratorList entity={componentData} />
                      </>
                    )}
                  </span>
                </div>
              </div>
            </Header>
            {supportedDemos.length > 0 && (
              <div style={{ marginBottom: '10px', marginLeft: 'auto' }}>
                <Button onClick={() => setAttachDialogOpen(true)}>
                  Attach to Demo
                </Button>
              </div>
            )}
          </div>
          <Surface>
            <div style={{ marginBottom: '32px' }}>
              <Typography variant="h4">Overview</Typography>
              <Typography variant="body">
                <span className="pre-line-text">
                  {componentData.general.description}
                </span>
              </Typography>
            </div>

            <div style={{ marginBottom: '32px' }}>
              <Typography variant="h4">Supported Platforms</Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {componentData.dependsOn.idp?.types.includes(
                  'customer-identity'
                ) ? (
                  <CheckIcon color="success" />
                ) : (
                  <CloseIcon color="black" />
                )}
                <Typography variant="subordinate">
                  {t('product-ci-label', { ns: 'idp' })}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {componentData.dependsOn.idp?.types.includes(
                  'workforce-identity'
                ) ? (
                  <CheckIcon color="success" />
                ) : (
                  <CloseIcon color="black" />
                )}
                <Typography variant="subordinate">
                  {t('product-wi-label', { ns: 'idp' })}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {componentData.dependsOn.idp?.types.includes(
                  'okta-customer-identity'
                ) ? (
                  <CheckIcon color="success" />
                ) : (
                  <CloseIcon color="black" />
                )}
                <Typography variant="subordinate">
                  {t('product-oci-label', { ns: 'idp' })}
                </Typography>
              </div>
            </div>

            {metricData && (
              <div style={{ marginBottom: '32px' }}>
                <Typography variant="h4">Usage</Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Typography variant="subordinate">
                    Active Instances:
                  </Typography>
                  <Typography variant="subordinate">
                    {metricData
                      ? metricData.attachments.current.instances.active
                      : 'N/A'}
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Typography variant="subordinate">
                    Versions in Use:
                  </Typography>
                  {metricData.liveVersions ? (
                    Object.entries(metricData.liveVersions)
                      .filter(
                        ([key]) =>
                          key !== 'totalVersions' && key !== 'undefined'
                      )
                      .map(([key, value]) => (
                        <div
                          key={key}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <Typography variant="subordinate">
                            {key} - {value ? value.toString() : 'N/A'}
                          </Typography>
                        </div>
                      ))
                  ) : (
                    <Typography variant="body">N/A</Typography>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Typography variant="subordinate">
                    Linked Opportunities:
                  </Typography>
                  <Typography variant="subordinate">
                    {metricData
                      ? metricData.attachments.current.demos.tagged.count
                      : 'N/A'}
                  </Typography>
                </div>
              </div>
            )}

            <div style={{ marginBottom: '32px' }}>
              <Typography variant="h4">Reference Material</Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <DocumentationIcon />
                {componentData.general.documentationUri ? (
                  <Link
                    href={componentData.general.documentationUri}
                    target="_blank"
                  >
                    Documentation
                  </Link>
                ) : (
                  <Typography variant="subordinate">Documentation</Typography>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <VideoIcon />
                {componentData.general.videoUri ? (
                  <Link href={componentData.general.videoUri} target="_blank">
                    Video
                  </Link>
                ) : (
                  <Typography variant="subordinate">Video</Typography>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <ChatIcon />
                {componentData.general.supportUri ? (
                  <Link href={componentData.general.supportUri} target="_blank">
                    Support
                  </Link>
                ) : (
                  <Typography variant="subordinate">Support</Typography>
                )}
              </div>
            </div>
            <Dialog
              isOpen={attachDialogOpen}
              onClose={() => setAttachDialogOpen(false)}
              title={`Attach ${componentData.general.name} to an existing demo`}
              primaryCallToActionComponent={
                <AttachComponentButton
                  active={targetDemo !== null}
                  component={componentData}
                  attachEvent={attachComponent}
                  error={error}
                />
              }
              secondaryCallToActionComponent={
                <Button
                  isDisabled={waiting}
                  label="Cancel"
                  onClick={() => setAttachDialogOpen(false)}
                  variant="floating"
                />
              }
            >
              <div style={{ marginBottom: '16px' }}>
                <Typography variant="body">
                  This component may modify the configuration of your identity
                  provider to configure itself. Familizarize yourself with the
                  documentation before attaching.
                </Typography>
              </div>
              <DemoList
                demos={supportedDemos}
                selectionChanged={setTargetDemo}
              />
            </Dialog>
          </Surface>
        </div>
      ) : (
        <div className="skeletonContainer">
          <Stack spacing={1}>
            <Skeleton variant="rounded" width={250} height={150} />
            <Skeleton variant="rounded" width={350} height={250} />
            <Skeleton variant="rounded" width={350} height={250} />
          </Stack>
        </div>
      )}
    </>
  );
};

export default LibraryComponentDetails;
