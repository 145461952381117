import React, { useState, useRef } from 'react';
import { Button, Dialog } from '@okta/odyssey-react-mui';
import { hasUserFacingSetting } from '../../../util/Settings';
import axios from 'axios';
import InstanceSettings from '../../instance/InstanceSettings';
import ErrorMessage from '../../ErrorMessage';
import { useUserPreferenceContext } from '../../../UserPreferenceContext';
import { useTranslation } from 'react-i18next';

const AttachComponentButton = ({
  active = true,
  component,
  waiting,
  attachEvent,
  error,
}) => {
  const [fieldError, setFieldError] = useState('');
  const [personalSettings, setPersonalSettings] = useState({});
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const { awsAccountId, awsRegion } = useUserPreferenceContext();
  const instanceSettingsRef = useRef(null); // Create a ref for InstanceSettings
  const { t } = useTranslation(['core', 'component']);

  async function handleDialogOpen() {
    const personalSettings = {
      awsAccountId: awsAccountId(),
      awsRegion: awsRegion(),
    };
    await axios.get('https://api.ipify.org?format=json').then((response) => {
      personalSettings['InitialInboundIpAddress'] = response.data.ip;
    });

    setPersonalSettings(personalSettings);
    setSettingsDialogOpen(true);
  }

  return (
    <>
      {hasUserFacingSetting(JSON.parse(component.settings).properties) ? (
        <>
          <Button
            className="card-action branded"
            isDisabled={waiting || !active}
            label={t('button-attach-label', { ns: 'core' })}
            onClick={handleDialogOpen}
          />
          <Dialog
            isOpen={settingsDialogOpen}
            title={`${component.general.name} Settings`}
            primaryCallToActionComponent={
              <Button
                label={t('button-continue-label', { ns: 'core' })}
                isDisabled={waiting || fieldError}
                onClick={() => {
                  instanceSettingsRef.current?.validateForm();
                  if (instanceSettingsRef.current?.validateForm()) {
                    attachEvent(
                      component.componentId,
                      instanceSettingsRef.current.getFormData()
                    );
                    setSettingsDialogOpen(false);
                  }
                }}
              />
            }
            secondaryCallToActionComponent={
              <Button
                isDisabled={waiting}
                label={t('button-cancel-label', { ns: 'core' })}
                onClick={() => setSettingsDialogOpen(false)}
                variant="floating"
              />
            }
            onClose={() => setSettingsDialogOpen(false)}
          >
            {error && <ErrorMessage error={error} />}
            {fieldError && <ErrorMessage error={fieldError} persist={false} />}
            {t('instance-settings-description', { ns: 'component' })}
            <InstanceSettings
              ref={instanceSettingsRef} // Pass the ref to InstanceSettings
              schema={component.settings}
              settings={personalSettings}
              onError={setFieldError}
            />
          </Dialog>
        </>
      ) : (
        <Button
          className="card-action branded"
          isDisabled={waiting || !active}
          label={t('button-attach-label', { ns: 'core' })}
          onClick={() => {
            attachEvent(component.componentId);
            setSettingsDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default AttachComponentButton;
