import Ajv from 'ajv/dist/2020';
import addFormats from 'ajv-formats';

export default function useSchemaValidation() {
  const ajv = new Ajv({ strict: true });
  addFormats(ajv);

  function validateSchema(schema) {
    ajv.compile(schema);
  }

  function validateData(schema) {
    return ajv.getSchema(schema.$id) || ajv.compile(schema);
  }

  return { validateSchema, validateData };
}
