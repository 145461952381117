import { TextField, Switch } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';

const ComponentHookField = ({
  componentType,
  requestHook,
  setRequestHook,
  requestHookError,
  setRequestHookError,
  attachHook,
  setAttachHook,
  attachHookError,
  setAttachHookError,
  updateHook,
  setUpdateHook,
  updateHookError,
  setUpdateHookError,
  executeHook,
  setExecuteHook,
  executeHookError,
  setExecuteHookError,
  detachHook,
  setDetachHook,
  detachHookError,
  setDetachHookError,
  allHooksError,
  setAllHooksError,
  isAdvancedMode,
  setIsAdvancedMode,
}) => {
  const { t } = useTranslation(['core', 'component', 'demo']);

  function validateForm() {
    const validURlregex =
      '((https?)://)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
    var result = true;
    if (requestHook && !requestHook.match(validURlregex)) {
      setRequestHookError(
        t('field-requestHook-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setRequestHookError();
    }
    if (attachHook && !attachHook.match(validURlregex)) {
      setAttachHookError(
        t('field-attachHook-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setAttachHookError();
    }
    if (updateHook && !updateHook.match(validURlregex)) {
      setUpdateHookError(
        t('field-updateHook-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setUpdateHookError();
    }
    if (executeHook && !executeHook.match(validURlregex)) {
      setExecuteHookError(
        t('field-executeHook-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setExecuteHookError();
    }
    if (detachHook && !detachHook.match(validURlregex)) {
      setDetachHookError(
        t('field-detachHook-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setDetachHookError();
    }
    if (
      !requestHook &&
      !attachHook &&
      !updateHook &&
      !detachHook &&
      !executeHook
    ) {
      setAllHooksError(t('field-allHooks-error-missing', { ns: 'component' }));
    } else {
      setAllHooksError();
    }
    return result;
  }

  return (
    <>
      <Switch
        onChange={(e) => {
          setIsAdvancedMode(e.checked);
        }}
        value={isAdvancedMode}
        label="Advanced Mode"
      />
      {isAdvancedMode ? (
        <>
          <TextField
            label={t('field-requestHook-label', { ns: 'component' })}
            hint={t('field-requestHook-hint', { ns: 'component' })}
            placeholder={t('field-requestHook-placeholder', {
              ns: 'component',
            })}
            onChange={(e) => setRequestHook(e.target.value)}
            onBlur={validateForm}
            errorMessage={requestHookError}
            value={requestHook}
            isOptional={
              componentType === 'app' ||
              (!attachHook && !updateHook && !executeHook && !detachHook)
            }
            type={'url'}
          />
          <TextField
            label={t('field-attachHook-label', { ns: 'component' })}
            hint={t('field-attachHook-hint', { ns: 'component' })}
            placeholder={t('field-attachHook-placeholder', {
              ns: 'component',
            })}
            onChange={(e) => setAttachHook(e.target.value)}
            onBlur={validateForm}
            errorMessage={attachHookError}
            value={attachHook}
            isOptional={
              componentType === 'app' ||
              (!requestHook && !updateHook && !executeHook && !detachHook)
            }
            type={'url'}
          />
          <TextField
            label={t('field-updateHook-label', { ns: 'component' })}
            hint={t('field-updateHook-hint', { ns: 'component' })}
            placeholder={t('field-updateHook-placeholder', {
              ns: 'component',
            })}
            onChange={(e) => setUpdateHook(e.target.value)}
            onBlur={validateForm}
            errorMessage={updateHookError}
            value={updateHook}
            isOptional={
              componentType === 'app' ||
              (!requestHook && !attachHook && !executeHook && !detachHook)
            }
            type={'url'}
          />
          <TextField
            label={t('field-executeHook-label', { ns: 'component' })}
            hint={t('field-executeHook-hint', { ns: 'component' })}
            placeholder={t('field-executeHook-placeholder', {
              ns: 'component',
            })}
            onChange={(e) => setExecuteHook(e.target.value)}
            onBlur={validateForm}
            errorMessage={executeHookError}
            value={executeHook}
            isOptional={
              componentType === 'app' ||
              (!requestHook && !attachHook && !updateHook && !detachHook)
            }
            type={'url'}
          />
          <TextField
            label={t('field-detachHook-label', { ns: 'component' })}
            hint={t('field-detachHook-hint', { ns: 'component' })}
            placeholder={t('field-detachHook-placeholder', {
              ns: 'component',
            })}
            onChange={(e) => setDetachHook(e.target.value)}
            onBlur={validateForm}
            errorMessage={detachHookError}
            value={detachHook}
            isOptional={
              componentType === 'app' ||
              (!requestHook && !attachHook && !updateHook && !executeHook)
            }
            type={'url'}
          />
        </>
      ) : (
        <TextField
          label={t('field-allHooks-label', { ns: 'component' })}
          hint={t('field-allHooks-hint', { ns: 'component' })}
          type={'url'}
          isOptional={
            componentType === 'app' ||
            (!attachHook &&
              !requestHook &&
              !updateHook &&
              !executeHook &&
              !detachHook)
          }
          placeholder={t('field-allHooks-placeholder', { ns: 'component' })}
          errorMessageList={[
            allHooksError,
            requestHookError,
            attachHookError,
            updateHookError,
            executeHookError,
            detachHookError,
          ].filter((error) => error !== undefined)}
          onBlur={validateForm}
          onChange={(e) => {
            setRequestHook(e.target.value);
            setAttachHook(e.target.value);
            setUpdateHook(e.target.value);
            setExecuteHook(e.target.value);
            setDetachHook(e.target.value);
          }}
        />
      )}
    </>
  );
};
export default ComponentHookField;
