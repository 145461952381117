import React, { useState, useEffect } from 'react';
import { Checkbox, Fieldset } from '@okta/odyssey-react-mui';
import Container from './ui/Container/Container';
import { useTranslation } from 'react-i18next';
const Dependencies = ({ dependencies, onChange, allowEdit }) => {
  const { t } = useTranslation(['idp', 'component', 'demo', 'core']);

  const [supportsCIC, setSupportsCIC] = useState(
    dependencies?.idp?.types?.includes('customer-identity')
  );
  const [supportsOCIS, setSupportsOCIS] = useState(
    dependencies?.idp?.types?.includes('okta-customer-identity')
  );
  const [supportsWIC, setSupportsWIC] = useState(
    dependencies?.idp?.types?.includes('workforce-identity')
  );

  function handleChange(event) {
    switch (event.target.name) {
      case 'dependency_cic':
        setSupportsCIC(event.target.checked);
        break;
      case 'dependency_wic':
        setSupportsWIC(event.target.checked);
        break;
      case 'dependency_ocis':
        setSupportsOCIS(event.target.checked);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (dependencies) {
      setSupportsCIC(dependencies?.idp?.types?.includes('customer-identity'));
      setSupportsOCIS(
        dependencies?.idp?.types?.includes('okta-customer-identity')
      );
      setSupportsWIC(dependencies?.idp?.types?.includes('workforce-identity'));
    }
  }, [dependencies]);

  useEffect(() => {
    var types = [];
    if (supportsCIC) {
      types.push('customer-identity');
    }
    if (supportsOCIS) {
      types.push('okta-customer-identity');
    }
    if (supportsWIC) {
      types.push('workforce-identity');
    }

    var updated = {
      idp: { types: types },
    };
    onChange(updated);
  }, [supportsCIC, supportsOCIS, supportsWIC, onChange]);

  return (
    <Container>
      <Fieldset>
        <Checkbox
          id="dependency_cic"
          label={t('dependency-ci-label', { ns: 'idp' })}
          isChecked={supportsCIC || false}
          value={supportsCIC || false}
          onChange={handleChange}
          control={Checkbox}
          isDisabled={!allowEdit}
        />

        <Checkbox
          id="dependency_wic"
          label={t('dependency-wi-label', { ns: 'idp' })}
          isChecked={supportsWIC || false}
          value={supportsWIC || false}
          onChange={handleChange}
          control={Checkbox}
          isDisabled={!allowEdit}
        />

        <Checkbox
          id="dependency_ocis"
          label={t('dependency-oci-label', { ns: 'idp' })}
          isChecked={supportsOCIS || false}
          value={supportsOCIS || false}
          onChange={handleChange}
          control={Checkbox}
          isDisabled={!allowEdit}
        />
      </Fieldset>
    </Container>
  );
};
export default Dependencies;
