import { Box, Switch } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';
import { EditableTextField } from '../fields';
import { useState } from 'react';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { useOdysseyRJSF, useSchemaValidation } from '../../util';

function ComponentSettingsSchema({ component, updateComponent }) {
  const { t } = useTranslation(['core', 'component', 'demo']);
  const [error, setError] = useState();
  const [previewMode, setPreviewMode] = useState(false);
  const { widgets, uiSchema, template } = useOdysseyRJSF();
  const { validateSchema } = useSchemaValidation();
  const { componentType } = component;
  const [saveDisabled, setSaveDisabled] = useState(false);

  function validate(event) {
    try {
      validateSchema(JSON.parse(event.target.value));
      setError();
      setSaveDisabled(false);
    } catch (error) {
      setSaveDisabled(true);
      setError(error.message);
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Switch
        onChange={(e) => {
          setPreviewMode(e.checked);
        }}
        value={previewMode}
        label="Preview"
      />
      {previewMode ? (
        <Form
          uiSchema={uiSchema}
          schema={JSON.parse(component?.settings)}
          validator={validator}
          widgets={widgets}
          templates={template}
        />
      ) : (
        <EditableTextField
          label={t('field-settingsSchema-label', { ns: 'component' })}
          hint={t('field-settingsSchema-hint', { ns: 'component' })}
          value={JSON.stringify(JSON.parse(component?.settings), null, 2)}
          isDisabled={saveDisabled}
          errorMessage={error}
          multiline={true}
          onBlur={(value) => validate(value)}
          onSave={(value) =>
            updateComponent({
              componentType,
              settings: value,
            })
          }
        />
      )}
    </Box>
  );
}

export default ComponentSettingsSchema;
