import { AppsIcon } from '@okta/odyssey-react-mui/icons';
import { useTranslation } from 'react-i18next';
import WebhookIcon from '@mui/icons-material/Webhook';

const ComponentCategoryTypeLabel = ({ type, category }) => {
  const { t } = useTranslation(['core', 'component', 'demo']);

  switch (type) {
    case 'app':
      return (
        <>
          <AppsIcon />{' '}
          {category && t(`${category}-category`, { ns: 'component' })}{' '}
          {t('app-type', { ns: 'component' })}
        </>
      );
    case 'resource':
      return (
        <>
          <WebhookIcon />{' '}
          {category && t(`${category}-category`, { ns: 'component' })}{' '}
          {t('resource-type', { ns: 'component' })}
        </>
      );
    default:
      break;
  }
};
export default ComponentCategoryTypeLabel;
