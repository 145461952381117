import OpenAPIClientAxios from 'openapi-client-axios';
import Config from '../Config';

export async function getOpenApiClient(accessToken) {
  const api = new OpenAPIClientAxios({
    definition: 'https://docs.demo.okta.com/spec/openapi-310-spec.yaml',
    withServer: Config.resourceServer.environmentName,
  });
  const apiClient = await api.getClient();

  apiClient.interceptors.request.use((config) => {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      ...config.headers,
    };

    return config;
  });

  return { apiClient };
}
