import TimeAgo from 'javascript-time-ago';
import i18n from '../i18n';

// English.
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
export default new TimeAgo(i18n.language);
