const insights = {
  label: 'Insights',
  //logo: '',
  description:
    "Showcase CIC's ability to integrate with log streaming services. In this app, you'll be able to show security personas how CIC can surface security event data.",
  componentType: 'resource',
  link: 'https://insights.demo.okta.com/',
  state: 'active',
};
const mobileExperiencePortal = {
  label: 'Mobile Experience Portal',
  //logo: '',
  description:
    'Provides an emulated mobile experience that can be shared in your browser.',
  componentType: 'app',
  link: 'https://mobile.oktademo.engineering/',
  state: 'active',
};

export default { insights, mobileExperiencePortal };
