import React, { useState, useEffect, useCallback } from 'react';
import './Resources.css';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Config from '../Config';
import ErrorMessage from './ErrorMessage';
import StateLabel from './StateLabel';
import CategoryLabel from './CategoryLabel';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'flagsmith/react';
import Header from './ui/Header/Header';
import Image from './ui/Image/Image';
import { Callout, CircularProgress } from '@okta/odyssey-react-mui';
import { Button, Card } from '@okta/odyssey-react-mui';
import { AddIcon } from '@okta/odyssey-react-mui/icons';
import CardGroup from './ui/CardGroup/CardGroup';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const Resources = () => {
  const navigate = useNavigate();
  const [resources, setResources] = useState();
  const [error, setError] = useState(null);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const flags = useFlags(['resource_limits']);

  const getResources = useCallback(async () => {
    setError();
    if (isAuthenticated) {
      axios
        .get(Config.resourceServer.demoAPI + '/resources', {
          headers: {
            Authorization: 'Bearer ' + (await getAccessTokenSilently()),
          },
          params: { owner: user.email },
        })
        .then((response) => {
          setResources(response.data);
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setResources([]);
    }
  }, [getAccessTokenSilently, isAuthenticated, setResources, user.email]);

  useEffect(() => {
    if (isAuthenticated) {
      getResources();
    } else {
      setResources([]);
    }
  }, [getResources, isAuthenticated, getAccessTokenSilently, setResources]);

  function handleOnClick(path) {
    navigate(path);
  }

  const bannerContent = (
    <>
      {' '}
      <p>
        Add your own custom resources to the demo library to easily reuse
        components between demos or build a true custom experience.
      </p>
      <p>
        Learn how to get started building custom demo resources with our docs{' '}
        <a target="_blank" rel="noreferrer" href="https://docs.demo.okta.com/">
          here
        </a>
        .
      </p>
    </>
  );

  return (
    <div className="appComponent">
      <Header className="contentHeader">Your Resources</Header>

      {resources ? (
        <div className="mainContainer">
          <Button
            className="createActionButton branded"
            variant="primary"
            startIcon={<AddIcon />}
            label="New Resource"
            onClick={() => handleOnClick('/resource')}
            isDisabled={
              flags.resource_limits.enabled &&
              resources.length >= flags.resource_limits.value
            }
          />
          {flags.resource_limits.enabled &&
            resources.length >= flags.resource_limits.value && (
              <Callout
                severity="error"
                text="You've reached the maximum number of resources allowed."
              />
            )}
          {resources.length !== 0 ? (
            <CardGroup className="hoverDarkerCard">
              {resources.map((resource) => (
                <Card
                  key={resource.id}
                  title={resource.name}
                  onClick={() =>
                    handleOnClick(`/resource/${resource.resource_id}`)
                  }
                  description={
                    <>
                      {resource.logo ? (
                        <Image
                          floated="right"
                          size="tiny"
                          className="identicon"
                          src={resource.logo}
                        />
                      ) : (
                        <Image
                          floated="right"
                          size="tiny"
                          className="identicon"
                          src="https://cdn.demo.okta.com/images/identicons-set-v2/gray/svg/icon-integrations-marketplace.svg"
                        />
                      )}

                      <div>
                        <CategoryLabel category={resource.category} />
                      </div>
                      <StateLabel state={resource.state} />

                      {resource.baseAddress}
                    </>
                  }
                ></Card>
              ))}
            </CardGroup>
          ) : (
            <Callout
              role="status"
              severity="info"
              text={bannerContent}
              title="Resources"
            />
          )}
        </div>
      ) : error ? (
        <ErrorMessage error={error} retryAction={getResources} />
      ) : (
        !error && (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={150} height={50} />
              </Stack>
            </div>
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={250} height={210} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={250} height={210} />
              </Stack>
            </div>
          </div>
        )
      )}
    </div>
  );
};
export default Resources;
