import {
  Button,
  MenuButton,
  MenuItem,
  Toast,
  ToastStack,
  CircularProgress,
} from '@okta/odyssey-react-mui';
import InstanceSettings from './InstanceSettings';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage';
import { sortObjectByKeys } from '../../util/SortObjectByKeys';
import './InstanceConfiguration.css';
import { StorytimeTemplates } from '../../util/StorytimeTemplates';
import { hasUserFacingSetting } from '../../util/Settings';
import Container from '../ui/Container/Container';
import { useServiceContext } from '../../context';
import { ImportSettingsModal, PresetsModal } from '../modals';
import { useTranslation } from 'react-i18next';

const InstanceConfiguration = ({ instance, isWaiting, onUpdate }) => {
  let params = useParams();
  const { componentId } = instance;

  const [componentInstance, setComponentInstance] = useState(instance);
  const [waiting, setWaiting] = useState(isWaiting);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState();
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [isPresetModalOpen, setPresetModalOpen] = useState(false);
  const [toast, setToast] = useState(false);
  const { componentInstanceService } = useServiceContext();
  const { t } = useTranslation(['core', 'component', 'demo']);
  const instanceSettingsRef = useRef(null); // Create a ref for InstanceSettings

  useEffect(() => {
    setSettings(JSON.parse(JSON.stringify(instance.instanceDetails.settings)));
  }, [instance.instanceDetails.settings]);

  useEffect(() => {
    setSettings(JSON.parse(JSON.stringify(instance.instanceDetails.settings)));
  }, [instance.instanceDetails.settings]);

  const handlePresetSelection = (selectedPresetName) => {
    const selectedPreset = StorytimeTemplates.filter(
      (preset) => preset.displayName === selectedPresetName
    )[0];
    setSettings({ componentId, settings: selectedPreset.settings });
    setPresetModalOpen(false);
  };

  const handleExport = () => {
    const settingsData = structuredClone({
      componentId,
      settings,
    });

    navigator.clipboard
      .writeText(JSON.stringify(settingsData))
      .then(() => {
        setToast(true);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleImport = (importedSettings) => {
    setSettings(importedSettings.settings);
    setImportModalOpen(false);
  };

  const handleHide = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  };

  async function updateInstance() {
    setWaiting(true);

    const updates = {
      settings: instanceSettingsRef.current.getFormData(),
    };

    componentInstanceService
      .updateComponentInstance(params.demoName, params.componentId, updates)
      .then((response) => {
        setComponentInstance(response);
        setSettings(
          JSON.parse(JSON.stringify(response.instanceDetails.settings))
        );
        onUpdate(response);
        setWaiting(false);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  return (
    <Container>
      <ImportSettingsModal
        componentId={componentId}
        isOpen={isImportModalOpen}
        setOpen={setImportModalOpen}
        onImport={handleImport}
        schema={JSON.parse(componentInstance.componentDetails.settings)}
      />
      <PresetsModal
        isOpen={isPresetModalOpen}
        setOpen={setPresetModalOpen}
        onPresetSelection={handlePresetSelection}
      />

      <ToastStack>
        <Toast
          isVisible={toast}
          autoHideDuration={2000}
          onHide={handleHide}
          role="status"
          severity="info"
          text={t('toast-settings-copied', { ns: 'component' })}
        />
      </ToastStack>
      {error ? <ErrorMessage error={error} /> : null}
      {settings &&
      hasUserFacingSetting(
        JSON.parse(componentInstance.componentDetails.settings).properties
      ) ? (
        <Container>
          <InstanceSettings
            ref={instanceSettingsRef} // Pass the ref to InstanceSettings
            schema={componentInstance.componentDetails.settings}
            settings={settings}
          >
            <div className="buttonContainer">
              {waiting && <CircularProgress />}
              <MenuButton
                buttonLabel={t('button-more-label', { ns: 'core' })}
                buttonVariant="secondary"
                menuAlignment="left"
              >
                <MenuItem
                  onClick={() => {
                    setImportModalOpen(true);
                  }}
                >
                  {t('menuItem-import-label', { ns: 'component' })}
                </MenuItem>
                <MenuItem onClick={handleExport}>
                  {t('menuItem-export-label', { ns: 'component' })}
                </MenuItem>
                {/* Use Presets only available for storytime */}
                {instance.componentDetails.name === 'Storytime' && (
                  <MenuItem onClick={() => setPresetModalOpen(true)}>
                    {t('presets-menuItem-label', { ns: 'component' })}
                  </MenuItem>
                )}
              </MenuButton>
              <Button
                isDisabled={waiting}
                className="branded"
                type="submit"
                variant="primary"
                label={t('button-update-label', { ns: 'core' })}
                onClick={() => {
                  if (instanceSettingsRef.current?.validateForm()) {
                    updateInstance();
                  } else {
                    setError(t('form-validation-error', { ns: 'component' }));
                  }
                }}
              ></Button>
            </div>
          </InstanceSettings>
        </Container>
      ) : null}
    </Container>
  );
};

export default InstanceConfiguration;
