export function useOpportunityService(client) {
  if (!client) return null;
  async function listOpportunities() {
    const res = await client.listOpportunity();
    return res.data;
  }

  return {
    listOpportunities,
  };
}
