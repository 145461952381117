import { Box, Fieldset, TextField } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';
import {
  CopyableField,
  EditableSelectField,
  EditableTextField,
} from '../fields';
import { DemonstrationDiscoveryOptions } from '../DemonstrationDiscoveryConstants';

function AppComponentConfiguration({ component, updateComponent }) {
  const { t } = useTranslation(['core', 'component', 'demo']);
  const { componentType } = component;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Fieldset
        description={t('fieldset-applicationConfiguration-description', {
          ns: 'component',
        })}
        legend={t('fieldset-applicationConfiguration-legend', {
          ns: 'component',
        })}
      >
        <EditableTextField
          label={t('field-baseAddress-label', { ns: 'component' })}
          hint={t('field-baseAddress-hint', { ns: 'component' })}
          placeholder={t('field-baseAddress-placeholder', { ns: 'component' })}
          type={'url'}
          value={component?.applicationConfiguration?.baseAddress}
          onSave={(value) =>
            updateComponent({
              componentType,
              general: {
                applicationConfiguration: { baseAddress: value },
              },
            })
          }
        />
        <EditableSelectField
          label={t('field-multiTenancy-label', { ns: 'component' })}
          hint={t('field-multiTenancy-hint', { ns: 'component' })}
          options={DemonstrationDiscoveryOptions}
          value={component?.applicationConfiguration?.multiTenancy}
          onSave={(value) =>
            updateComponent({
              componentType,
              general: {
                applicationConfiguration: { multiTenancy: value },
              },
            })
          }
        />
      </Fieldset>

      <Fieldset
        description={t('fieldset-platformIntegration-description', {
          ns: 'component',
        })}
        legend={t('fieldset-platformIntegration-legend', { ns: 'component' })}
      >
        <CopyableField
          label={t('field-componentId-label', { ns: 'component' })}
          hint={t('field-componentId-hint', { ns: 'component' })}
          value={component.componentId}
          childField={
            <TextField
              label={t('field-componentId-label', { ns: 'component' })}
              hint={t('field-componentId-hint', { ns: 'component' })}
              defaultValue={component.componentId}
              isReadOnly
            />
          }
        />
        <CopyableField
          label={t('field-tokenUrl-label', { ns: 'component' })}
          hint={t('field-tokenUrl-hint', { ns: 'component' })}
          value={
            component?.applicationConfiguration?.oidcConfiguration.tokenUrl
          }
          childField={
            <TextField
              label={t('field-tokenUrl-label', { ns: 'component' })}
              hint={t('field-tokenUrl-hint', { ns: 'component' })}
              defaultValue={
                component?.applicationConfiguration?.oidcConfiguration.tokenUrl
              }
              isReadOnly
            />
          }
        />
        <CopyableField
          label={t('field-clientId-label', { ns: 'component' })}
          hint={t('field-clientId-hint', { ns: 'component' })}
          value={
            component?.applicationConfiguration?.oidcConfiguration.client_id
          }
          childField={
            <TextField
              label={t('field-clientId-label', { ns: 'component' })}
              hint={t('field-clientId-hint', { ns: 'component' })}
              defaultValue={
                component?.applicationConfiguration?.oidcConfiguration.client_id
              }
              isReadOnly
            />
          }
        />
        <CopyableField
          label={t('field-clientSecret-label', { ns: 'component' })}
          hint={t('field-clientSecret-hint', { ns: 'component' })}
          value={
            component?.applicationConfiguration?.oidcConfiguration.client_secret
          }
          childField={
            <TextField
              label={t('field-clientSecret-label', { ns: 'component' })}
              hint={t('field-clientSecret-hint', { ns: 'component' })}
              defaultValue={
                component?.applicationConfiguration?.oidcConfiguration
                  .client_secret
              }
              type="password"
              isReadOnly
            />
          }
        />
      </Fieldset>
    </Box>
  );
}

export default AppComponentConfiguration;
