export function useComponentService(client) {
  async function listOwnedComponents(user) {
    const res = await client.listComponent({
      owner: user.email,
    });
    return res.data;
  }

  async function listComponents({ categories, idpTypes, owner }) {
    const payload = [];
    if (categories)
      categories.forEach((category) =>
        payload.push({ name: 'categories', value: category, in: 'query' })
      );
    if (idpTypes)
      idpTypes.forEach((type) =>
        payload.push({ name: 'supports_idp_type', value: type, in: 'query' })
      );
    if (owner) payload.push({ name: 'owner', value: owner, in: 'query' });
    const res = await client.listComponent(payload, { useCache: true });
    return res.data;
  }

  async function createComponent(
    general,
    lifecycle,
    settings,
    dependsOn,
    componentType,
    applicationConfiguration,
    collaborators
  ) {
    const res = await client.createComponent(null, {
      general,
      lifecycle,
      settings,
      dependsOn,
      componentType,
      applicationConfiguration,
      collaborators,
    });
    return res.data;
  }

  async function retrieveComponent(componentId) {
    const res = await client.retrieveComponent({ componentId });
    return res.data;
  }

  async function retrieveComponentMetrics(componentId) {
    const res = await client.retrieveComponentMetrics({ componentId });
    return res.data;
  }

  async function updateComponent(componentId, updates) {
    const res = await client.updateComponent({ componentId }, updates);
    return res.data;
  }

  async function deleteComponent(componentId) {
    return client.deleteComponent({ componentId });
  }

  return {
    listOwnedComponents,
    listComponents,
    createComponent,
    retrieveComponent,
    retrieveComponentMetrics,
    updateComponent,
    deleteComponent,
  };
}
