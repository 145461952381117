export function useCollaborationService(client) {
  async function addApplicationCollaborators(applicationId, ...collaborators) {
    const res = await client.shareApplication(applicationId, collaborators);
    return res.data;
  }

  async function removeApplicationCollaborators(
    applicationId,
    ...collaborators
  ) {
    const res = await client.unshareApplication(applicationId, collaborators);
    return res.data;
  }

  async function addComponentCollaborators(componentId, ...collaborators) {
    const res = await client.shareComponent(componentId, collaborators);
    return res.data;
  }

  async function removeComponentCollaborators(componentId, ...collaborators) {
    const res = await client.unshareComponent(componentId, collaborators);
    return res.data;
  }

  async function addDemoCollaborators(demoName, ...collaborators) {
    const res = await client.shareDemonstration(demoName, collaborators);
    return res.data;
  }

  async function removeDemoCollaborators(demoName, ...collaborators) {
    const res = await client.unshareDemonstration(demoName, collaborators);
    return res.data;
  }

  async function addResourceCollaborators(resourceId, ...collaborators) {
    const res = await client.shareResource(resourceId, collaborators);
    return res.data;
  }

  async function removeResourceCollaborators(resourceId, ...collaborators) {
    const res = await client.unshareResource(resourceId, collaborators);
    return res.data;
  }

  return {
    addApplicationCollaborators,
    addComponentCollaborators,
    addDemoCollaborators,
    addResourceCollaborators,
    removeApplicationCollaborators,
    removeComponentCollaborators,
    removeDemoCollaborators,
    removeResourceCollaborators,
  };
}
