//Environment specific
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const DOMAIN = process.env.REACT_APP_DOMAIN;
const DEMO_API_ENDPOINT = process.env.REACT_APP_DEMOAPI_ENDPOINT;
const DEMO_API_ENVIRONMENT = process.env.REACT_APP_DEMOAPI_ENVIRONMENT;
const AUDIENCE = process.env.REACT_APP_AUDIENCE;
const FLAGSMITH_ENVIRONMENT = process.env.REACT_APP_FLAGSMITH_ENVIRONMENT;
const AWS_CROSS_ACCOUNT_LINK = process.env.REACT_APP_AWS_CROSS_ACCOUNT_LINK;

//Application vars
const REDIRECT_URI = `${window.location.origin}`;
const SCOPE =
  'application:read application:write application:delete ' + // App Scopes (phasing out)
  'resource:read resource:write resource:delete ' + // Resource Scopes (phasing out)
  'demonstration:read demonstration:write demonstration:delete ' + // Demo Scopes
  'idp:read idp:write idp:delete ' + // Identity Provider Scopes
  'opportunity:read ' + // Opportunity Scopes
  'component:read component:write component:delete'; // Component Scopes

const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: DOMAIN,
    redirectUri: REDIRECT_URI,
    scopes: SCOPE,
    audience: AUDIENCE,
  },
  resourceServer: {
    demoAPI: DEMO_API_ENDPOINT,
    environmentName: DEMO_API_ENVIRONMENT,
  },
  features: {
    environmentID: FLAGSMITH_ENVIRONMENT,
    awsCrossAccountLink: AWS_CROSS_ACCOUNT_LINK,
  },
};
export default config;
