import { useTranslation } from 'react-i18next';

const IdpType = ({ idp }) => {
  const { t } = useTranslation(['idp']);
  switch (idp.type) {
    case 'customer-identity':
      return <span>{t('product-ci-label')}</span>;
    case 'okta-customer-identity':
      return <span>{t('product-oci-label')}</span>;
    case 'workforce-identity':
      switch (idp.variant) {
        case 'preview':
          return (
            <span>
              {t('product-wi-label')} {t('product-variant-preview')}
            </span>
          );
        case 'gcp':
          return (
            <span>
              {t('product-wi-label')} {t('product-variant-gcp')}
            </span>
          );
        default:
          return <span>{t('product-wi-label')}</span>;
      }
    default:
      break;
  }
};
export default IdpType;
