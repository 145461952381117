export function useIdentityProviderService(client) {
  if (!client) return null;
  async function listIdentityProviders() {
    const res = await client.listIdentityProvider();
    return res.data;
  }

  async function createIdentityProvider(name, idpType, variant = 'production') {
    const res = await client.createIdentityProvider(null, {
      name: name,
      type: idpType,
      variant,
    });
    return res.data;
  }

  async function getIdentityProvider(idpId) {
    const res = await client.retrieveIdentityProvider(idpId);
    return res.data;
  }

  return {
    listIdentityProviders,
    createIdentityProvider,
    getIdentityProvider,
  };
}
