import { Button, Tooltip } from '@okta/odyssey-react-mui';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const SummarisedText = ({ value, maxLength = 80 }) => {
  return (
    <div className="buttonStyleOverflow">
      {value?.length > maxLength ? (
        <Tooltip placement="top" text={value}>
          {value.substring(0, value.indexOf(' ', maxLength))}{' '}
          <Button
            variant="secondary"
            size="small"
            style={{ padding: 0 }}
            startIcon={<MoreHorizIcon />}
          ></Button>
        </Tooltip>
      ) : (
        value
      )}
    </div>
  );
};

export default SummarisedText;
