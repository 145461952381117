import { Typography } from '@okta/odyssey-react-mui';
import timeAgo from '../../util/timeAgo';
import { ClockIcon, WarningIcon } from '@okta/odyssey-react-mui/icons';
import { useTranslation } from 'react-i18next';

const LastActivityLabel = ({ lastActive }) => {
  const { t } = useTranslation(['demo']);
  const nintyDaysAgo = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
  const notActiveRecently = new Date(lastActive) <= nintyDaysAgo;
  return (
    <>
      <Typography variant="subordinate">
        {lastActive === undefined || notActiveRecently ? (
          <WarningIcon />
        ) : (
          <ClockIcon />
        )}
      </Typography>
      <Typography variant="subordinate">
        {lastActive === undefined
          ? t('label-last-activity-never', { ns: 'demo' })
          : t('label-last-activity', {
              ns: 'demo',
              ago: timeAgo.format(new Date(lastActive), 'round-day'),
            })}
      </Typography>
    </>
  );
};

export default LastActivityLabel;
