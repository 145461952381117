import './InstanceSettings.css';
import { Form } from '@rjsf/mui';
import { useOdysseyRJSF } from '../../util';
import { useRef, useState } from 'react';
import ErrorMessage from '../ErrorMessage';
import { forwardRef, useImperativeHandle } from 'react';
import { customizeValidator } from '@rjsf/validator-ajv8';
import Ajv2020 from 'ajv/dist/2020';

const InstanceSettings = forwardRef(({ schema, settings, children }, ref) => {
  const { widgets, uiSchema, template, errorTransformer } = useOdysseyRJSF();
  const [error, setError] = useState();

  const validator = customizeValidator({ AjvClass: Ajv2020 });

  const formRef = useRef();

  function validate() {
    return formRef?.current?.validateForm();
  }

  useImperativeHandle(ref, () => ({
    validateForm: () => {
      return validate();
    },
    getFormData: () => formRef.current?.state?.formData, // Expose the latest form data
    setFormData: (formData) => {
      if (formRef.current) {
        formRef.current.state.formData = formData; // Update the form data
      }
    },
  }));

  return (
    <>
      {error ? <ErrorMessage error={error} /> : null}
      <Form
        schema={JSON.parse(schema)}
        transformErrors={errorTransformer}
        formData={settings}
        validator={validator}
        uiSchema={uiSchema}
        templates={template}
        widgets={widgets}
        onBlur={(e) => {
          validate();
        }}
        // onError={(e) => {
        //   console.log(e);
        //   setError('Correct the errors in the form.');
        // }}
        omitExtraData
        liveOmit
        ref={formRef}
      >
        {children}
      </Form>
    </>
  );
});

// Add display name for debugging purposes
InstanceSettings.displayName = 'InstanceSettings';

export default InstanceSettings;
