import { Button, Dialog, Select } from '@okta/odyssey-react-mui';
import { StorytimeTemplates } from '../../util/StorytimeTemplates';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PresetsModal = ({ isOpen, setOpen, onPresetSelection }) => {
  const { t } = useTranslation(['core', 'component', 'demos']);
  const [selectedPreset, setSelectedPreset] = useState();

  return (
    <Dialog
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      primaryCallToActionComponent={
        <Button
          label={t('button-apply-label', { ns: 'core' })}
          onClick={() => onPresetSelection(selectedPreset)}
          variant="primary"
        />
      }
      tertiaryCallToActionComponent={
        <Button
          label={t('button-cancel-label', { ns: 'core' })}
          onClick={() => setOpen(false)}
          variant="floating"
        />
      }
      title={t('presets-title', { ns: 'component' })}
    >
      <Select
        defaultValue=""
        hint={t('presets-select-hint', { ns: 'component' })}
        onChange={(e) => setSelectedPreset(e.target.value)}
        options={StorytimeTemplates.map((value) => value.displayName)}
      />
    </Dialog>
  );
};

export default PresetsModal;
