import React, { useCallback } from 'react';
import './demos.css';
import ErrorMessage from '../components/ErrorMessage';
import DemoTypeLabel from '../components/DemoTypeLabel';
import IdpTypeLabel from '../components/IdpTypeLabel';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDemoContext } from '../context';
import { DataView } from '@okta/odyssey-react-mui/labs';
import {
  Box,
  BreadcrumbList,
  Button,
  Link,
  Surface,
  Typography,
} from '@okta/odyssey-react-mui';
import CollaboratorList from '../components/collaboration/CollaboratorList';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { AddIcon, HomeIcon } from '@okta/odyssey-react-mui/icons';
import { useTranslation } from 'react-i18next';
import Header from '../components/ui/Header/Header';
import { LastActivityLabel } from '../components/labels';

const Demos = () => {
  const { demos, idps, error } = useDemoContext();
  const demoContext = useDemoContext();
  const navigate = useNavigate();

  const { t } = useTranslation(['idp', 'component', 'demo', 'core']);

  function handleOnClick(path) {
    navigate(path);
  }

  const getData = useCallback(
    ({ search, filters }) => {
      let filteredData = structuredClone(demos);
      if (search && search.length > 0) {
        const lowerCaseSearch = search.toLowerCase();

        filteredData = filteredData.filter((row) =>
          Object.values(row).some((value) => {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(lowerCaseSearch);
            }
            return false;
          })
        );
      }

      function filterData(mainArray, secondArray, filters) {
        let idpTypeValues = [];
        filters.forEach((filter) => {
          if (filter.id === 'idpType' && filter.value?.length) {
            idpTypeValues = filter.value.map((v) => v.value);
          }
        });

        const filteredIdpIds = secondArray
          .filter(
            (item) =>
              idpTypeValues.length === 0 || idpTypeValues.includes(item.type)
          )
          .map((item) => item.idp_id);

        return mainArray.filter((item) => {
          const matchesIdpType =
            idpTypeValues.length === 0 || filteredIdpIds.includes(item.idp_id);
          const matchesOtherFilters = filters.every((filter) => {
            if (filter.id !== 'idpType' && filter.value?.length) {
              const selectedValues = filter.value.map((v) => v.value);
              return selectedValues.includes(item[filter.id]);
            }
            return true;
          });

          return matchesIdpType && matchesOtherFilters;
        });
      }
      if (filters && filteredData.length) {
        return filterData(filteredData, idps, filters);
      }
      return filteredData;
    },
    [demos, idps]
  );

  return (
    <div>
      <div>
        <BreadcrumbList>
          <RouterLink to="/">
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </RouterLink>
          <i href="">{t('demo-list-label', { ns: 'demo' })}</i>
        </BreadcrumbList>
      </div>
      <div
        className="componentHeader"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <div style={{ marginRight: '2rem' }}>
          <Header>
            <Box sx={{ marginBottom: ['2rem'] }}>
              <Typography variant="h1">
                {t('demo-list-title', { ns: 'demo' })}
              </Typography>
              <Typography variant="subordinate">
                {t('demo-list-description', { ns: 'demo' })}
              </Typography>
            </Box>
          </Header>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            className="createActionButton branded"
            label={t('button-createDemo-label', { ns: 'demo' })}
            startIcon={<AddIcon />}
            variant="primary"
            onClick={() => handleOnClick('/demo')}
          />
        </div>
      </div>
      <Surface>
        {error && <ErrorMessage error={error} />}
        {demoContext.loading ? (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={45} height={40} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={400} height={40} />
              </Stack>
            </div>
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
            </div>
          </div>
        ) : (
          <DataView
            emptyPlaceholder={
              <Box>
                <Typography variant="h3">
                  {t('demo-list-empty-title', { ns: 'demo' })}
                </Typography>
                <Typography variant="support">
                  {t('demo-list-empty-cta', { ns: 'demo' })}
                </Typography>
              </Box>
            }
            hasSearch
            hasFilters
            filters={[
              {
                id: 'type',
                label: t('filter-demo-type', { ns: 'demo' }),
                variant: 'multi-select',
                options: [
                  {
                    label: t('enablement-type', { ns: 'demo' }),
                    value: 'enablement',
                  },
                  {
                    label: t('opportunity-type', { ns: 'demo' }),
                    value: 'opportunity',
                  },
                  {
                    label: t('lab-type', { ns: 'demo' }),
                    value: 'lab',
                  },
                ],
              },
              {
                id: 'idpType',
                label: t('filter-idp-type', { ns: 'idp' }),
                variant: 'multi-select',
                options: [
                  {
                    label: t('product-ci-label', { ns: 'idp' }),
                    value: 'customer-identity',
                  },
                  {
                    label: t('product-wi-label', { ns: 'idp' }),
                    value: 'workforce-identity',
                  },
                  {
                    label: t('product-oci-label', { ns: 'idp' }),
                    value: 'okta-customer-identity',
                  },
                ],
              },
            ]}
            availableLayouts={['grid']}
            getData={getData}
            tableLayoutOptions={{
              columns: [
                { accessorKey: 'label', header: 'Name' },
                {
                  accessorKey: 'type',
                  header: t('filter-demo-type', { ns: 'demo' }),
                },
                {
                  id: 'idp_id',
                  accessorFn: (row) =>
                    idps.find((obj) => {
                      return obj.idp_id === row.idp_id;
                    }).type,
                  header: t('filter-idp-type', { ns: 'idp' }),
                },
                {
                  accessorKey: 'linked_opportunities.length',
                  header: 'Attached Opps',
                },
                { accessorKey: 'collaborators.length', header: 'Shared' },
              ],
              hasChangeableDensity: true,
              hasColumnResizing: true,
              hasColumnVisibility: true,
              hasSorting: true,
              initialDensity: 'comfortable',
              rowActionButton: (row) => (
                <>
                  <Button label={t('button-view-label', { ns: 'core' })} />
                </>
              ),
            }}
            cardLayoutOptions={{
              itemProps: (row) => ({
                overline: <DemoTypeLabel type={row.type} />,
                title: row.label,
                description: row.name,
                variant: 'compact',
                children: (
                  <Box>
                    <IdpTypeLabel idps={idps} idpid={row.idp_id} />
                    <Box
                      sx={{ display: 'flex', gap: '10px', paddingLeft: '7px' }}
                    >
                      <LastActivityLabel
                        lastActive={row.meta_data.last_active_on}
                      />
                    </Box>
                    <CollaboratorList entity={row} includeManage={false} />
                    <Box sx={{ display: 'flex', width: '100%' }}>
                      <Link
                        variant="monochrome"
                        onClick={() => handleOnClick('/demo/' + row.name)}
                      >
                        {t('button-view-label', { ns: 'core' })}
                      </Link>
                    </Box>
                  </Box>
                ),
              }),
            }}
          />
        )}
      </Surface>
    </div>
  );
};
export default Demos;
