export function useComponentInstanceService(apiClient) {
  async function attachComponent(
    demoName,
    componentId,
    label = undefined,
    settings = {}
  ) {
    const res = await apiClient.attachComponentInstance(
      { demoName },
      { componentId, label, settings }
    );
    return res.data;
  }

  async function retrieveComponentInstance(demoName, componentId) {
    const res = await apiClient.retrieveComponentInstance({
      demoName,
      componentId,
    });
    return res.data;
  }

  async function updateComponentInstance(
    demoName,
    componentId,
    { label, settings }
  ) {
    const res = await apiClient.updateComponentInstance(
      { demoName, componentId },
      { label, settings }
    );
    return res.data;
  }

  async function detachComponent(demoName, componentId) {
    return apiClient.detachComponentInstance({ demoName, componentId });
  }

  return {
    attachComponent,
    retrieveComponentInstance,
    updateComponentInstance,
    detachComponent,
  };
}
