import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import { useNavigate } from 'react-router';
import { useFlags } from 'flagsmith/react';
import {
  BreadcrumbList,
  Surface,
  Toast,
  ToastStack,
  Typography,
} from '@okta/odyssey-react-mui';
import Header from '../components/ui/Header/Header';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { DataView } from '@okta/odyssey-react-mui/labs';
import flagsmith from 'flagsmith';
import Image from '../components/ui/Image/Image';
import './DemoComponents.css';
import { useServiceContext } from '../context';
import { ComponentCard } from '../components/attachment';
import { useTranslation } from 'react-i18next';
import { ComponentCategoryTypeLabel } from '../components/labels';
import { HomeIcon } from '@okta/odyssey-react-mui/icons';

const DemoComponents = () => {
  let params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['core', 'component', 'demo']);
  const [demo, setDemo] = useState();
  const [idp, setIdp] = useState();
  const [components, setComponents] = useState();
  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const flags = useFlags(['community_access', 'components']);
  const [favorites, setFavorites] = useState([]);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [favoriteComponents, setFavoriteComponents] = useState([]);
  const {
    demoService,
    identityProviderService,
    componentService,
    componentInstanceService,
  } = useServiceContext();

  useEffect(() => {
    const fetchFavorites = () => {
      const userFavorites = JSON.parse(
        flagsmith.getTrait('favouriteComponents') || null
      );
      setFavorites(userFavorites || []);
    };
    fetchFavorites();
  }, [demo]);

  const toggleFavorite = async (componentId, componentName) => {
    if (favorites.includes(componentId)) {
      setToastMessage(`${componentName} removed from favourites`);
    } else {
      setToastMessage(`${componentName} added to favourites`);
    }
    const updatedFavorites = favorites.includes(componentId)
      ? favorites.filter((id) => id !== componentId)
      : [...favorites, componentId];
    await flagsmith.setTrait(
      'favouriteComponents',
      JSON.stringify(updatedFavorites)
    );
    setFavorites(updatedFavorites);
    setToast(true);
  };

  const getDemo = useCallback(async () => {
    setError();
    if (isAuthenticated && demoService) {
      try {
        let demo = await demoService.getDemo(params.demoName);
        setDemo(demo);
      } catch (err) {
        setError(err);
      }
    } else {
      setDemo();
    }
  }, [isAuthenticated, setDemo, params.demoName, demoService]);

  useEffect(() => {
    async function getIDPDetails() {
      try {
        let identityProvider =
          await identityProviderService.getIdentityProvider(demo.idp_id);
        setIdp(identityProvider);
      } catch (error) {
        setError(error);
      }
    }
    if (demo) {
      getIDPDetails();
    }
  }, [demo]);

  const filterAttachedComponents = (allComponents) => {
    demo.app_instances.forEach((appInstance) => {
      let index = allComponents.findIndex(
        (x) => x.application_id === appInstance.componentId
      );
      if (index !== -1) {
        allComponents.splice(index, 1);
      }
    });
    demo.resources_instances?.forEach((resourceInstance) => {
      let index = allComponents.findIndex(
        (x) => x.resource_id === resourceInstance.componentId
      );
      if (index !== -1) {
        allComponents.splice(index, 1);
      }
    });
    return allComponents;
  };

  const getComponents = useCallback(async () => {
    if (isAuthenticated && demo && idp && componentService) {
      const components = await componentService.listComponents({
        idpTypes: [idp.type],
      });
      setComponents(filterAttachedComponents(components));
    } else {
      setComponents([]);
    }
  }, [demo, isAuthenticated, flags.community_access, idp, componentService]);

  useEffect(() => {
    if (isAuthenticated) {
      getDemo();
    } else {
      setDemo();
    }
  }, [getDemo, isAuthenticated, setDemo]);

  useEffect(() => {
    getComponents();
  }, [demo, getComponents]);

  async function attachComponent(id, settings) {
    setWaiting(true);
    await componentInstanceService
      .attachComponent(params.demoName, id, undefined, settings)
      .then(() => {
        navigate('/demo/' + params.demoName);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  const handleHide = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  };

  const dataViewGetData = useCallback(
    ({ search, filters }) => {
      let filteredData = structuredClone(components);
      if (search && search.length > 0) {
        const lowerCaseSearch = search.toLowerCase();

        filteredData = filteredData.filter((row) =>
          Object.values(row).some((value) => {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(lowerCaseSearch);
            }
            return false;
          })
        );
      }
      function filterData(data, filters) {
        return data.filter((item) => {
          return filters.every((filter) => {
            if (!filter.value || filter.value.length === 0) {
              return true;
            }
            const selectedValues = filter.value.map((v) => v.value);
            const itemValue = item[filter.id];
            return selectedValues.includes(itemValue);
          });
        });
      }
      if (filters && filteredData.length) {
        filteredData = filterData(filteredData, filters);
      }

      return filteredData?.sort((a, b) => {
        const aIsFavorite = favorites.includes(a.componentId);
        const bIsFavorite = favorites.includes(b.componentId);

        if (aIsFavorite && !bIsFavorite) {
          return -1;
        }
        if (!aIsFavorite && bIsFavorite) {
          return 1;
        }
        return a.general.name.localeCompare(b.general.name);
      });
    },
    [components, favorites]
  );

  return (
    <div>
      <div
        className="componentHeader"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Header>
          <div>
            <BreadcrumbList>
              <RouterLink to="/">
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              </RouterLink>
              <RouterLink to="/demos">
                {t('demo-list-label', { ns: 'demo' })}
              </RouterLink>
              <RouterLink to={`/demo/${params.demoName}`}>
                {params.demoName}
              </RouterLink>
              <i href="">
                {t('components-library-title', { ns: 'component' })}
              </i>
            </BreadcrumbList>
          </div>
          {t('components-library-title', { ns: 'component' })}
          <Typography variant="subordinate">
            {t('components-library-description', {
              ns: 'component',
              demoName: params.demoName,
            })}
          </Typography>
        </Header>
      </div>
      <Surface className="appComponent">
        <ToastStack>
          <Toast
            isVisible={toast}
            autoHideDuration={2000}
            onHide={handleHide}
            role="status"
            severity="info"
            text={toastMessage}
          />
        </ToastStack>
        {error ? (
          <ErrorMessage error={error} retryAction={getComponents} />
        ) : null}

        {demo && idp && components ? (
          <DataView
            getData={dataViewGetData}
            availableLayouts={['grid']}
            hasSearch
            hasFilters
            filters={[
              {
                id: 'category',
                label: 'Category filter',
                variant: 'multi-select',
                options: [
                  {
                    label: t('private-category', { ns: 'component' }),
                    value: 'private',
                  },
                  {
                    label: t('managed-category', { ns: 'component' }),
                    value: 'managed',
                  },
                  {
                    label: t('community-category', { ns: 'component' }),
                    value: 'community',
                  },
                ],
              },
              {
                id: 'componentType',
                label: t('field-type-label', { ns: 'component' }),
                variant: 'multi-select',
                options: [
                  {
                    label: t('app-type', { ns: 'component' }),
                    value: 'application',
                  },
                  {
                    label: t('resource-type', { ns: 'component' }),
                    value: 'resource',
                  },
                ],
              },
            ]}
            cardLayoutOptions={{
              // rowActionMenuItems: (row) => (
              //   <>
              //     <MenuItem onClick={() => addToFavourite(row)}>Edit</MenuItem>
              //     <MenuItem onClick={() => removeFromFavourite(row)}>
              //       Delete
              //     </MenuItem>
              //   </>
              // ),

              itemProps: (row) => ({
                maxGridColumns: 4,

                overline: (
                  <>
                    <ComponentCategoryTypeLabel
                      type={row.componentType}
                      category={row.general.category}
                    />
                    <button
                      aria-label={
                        favorites.includes(row.componentId)
                          ? t('button-favourites-remove-aria-label', {
                              ns: 'component',
                            })
                          : t('button-favourites-add-aria-label', {
                              ns: 'component',
                            })
                      }
                      onClick={() =>
                        toggleFavorite(row.componentId, row.general.name)
                      }
                      role={
                        favorites.includes(row.componentId)
                          ? 'favourite'
                          : 'normal'
                      }
                      className="favorite-button"
                    >
                      {favorites.includes(row.componentId) ? '★' : '☆'}
                    </button>
                  </>
                ),
                title: (
                  <>
                    {row.general.name}
                    {row.general.logoUri && (
                      <Image
                        floated="right"
                        size="tiny"
                        src={row.logo || row.logoUri}
                        style={{ float: 'right', margin: '10px' }}
                      />
                    )}
                  </>
                ),
                children: (
                  <ComponentCard
                    component={row}
                    waiting={waiting}
                    attachEvent={attachComponent}
                  />
                ),
              }),
            }}
          />
        ) : (
          !error && (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <div className="skeletonContainer">
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={45} height={40} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={400} height={40} />
                </Stack>
              </div>
              <div className="skeletonContainer">
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
              </div>
            </div>
          )
        )}
      </Surface>
    </div>
  );
};
export default DemoComponents;
