import React from 'react';
import CollaboratorList from '../collaboration/CollaboratorList';
import './ComponentCard.css';
import { Link } from '@okta/odyssey-react-mui';
import AttachComponentButton from '../ui/Button/AttachComponentButton';
import { ComponentLinks, SummarisedText } from '../labels';

const ComponentCard = ({ component, waiting, attachEvent }) => {
  return (
    <div>
      <>
        <div>
          {component.general.category !== 'managed' && (
            <div>
              <CollaboratorList entity={component} />
            </div>
          )}

          {component.componentType === 'app' && (
            <Link href={component.applicationConfiguration.baseAddress}>
              {new URL(component.applicationConfiguration.baseAddress).host}
            </Link>
          )}
        </div>
        <div className="cardContent">
          <SummarisedText value={component.general.description} />
        </div>
        <div className="buttonContainerTooltip">
          <ComponentLinks
            docs={component.general.documentationUri}
            video={component.general.videoUri}
            support={component.general.supportUri}
            tags={component.general.tags}
          />
        </div>
        <div className="buttonContainerFooter">
          <AttachComponentButton
            component={component}
            waiting={waiting}
            attachEvent={attachEvent}
          />
        </div>
      </>
    </div>
  );
};
export default ComponentCard;
