import {
  AppComponentConfiguration,
  GeneralComponentDetails,
  ComponentLifecycle,
  ComponentDependencies,
  ComponentSettingsSchema,
} from '../components/tabs';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  Tabs,
  Dialog,
  BreadcrumbList,
  Paragraph,
  Typography,
  CircularProgress,
  Surface,
} from '@okta/odyssey-react-mui';
import { HomeIcon } from '@okta/odyssey-react-mui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { ComponentCategoryTypeLabel } from '../components/labels';
import CollaboratorList from '../components/collaboration/CollaboratorList';
import CollaborationModal from '../components/collaboration/CollaborationModal';
import { useServiceContext } from '../context';
import Header from '../components/ui/Header/Header';
import Image from '../components/ui/Image/Image';

const ComponentDetails = () => {
  const params = useParams();
  const { t } = useTranslation(['core', 'component', 'demo']);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const [component, setComponent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState();
  const [open, setOpen] = useState(false);
  const { componentService } = useServiceContext();

  const loadComponent = useCallback(async () => {
    setIsLoading(true);
    setError();
    if (isAuthenticated) {
      try {
        const component = await componentService.retrieveComponent(
          params.componentId
        );
        setComponent(component);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    } else {
      setComponent();
      setIsLoading(false);
    }
  }, [isAuthenticated, componentService, params.componentId]);

  async function updateComponent(updates) {
    try {
      let updatedComponent = await componentService.updateComponent(
        component.componentId,
        updates
      );
      setComponent(updatedComponent);
    } catch (err) {
      setError(err);
    }
  }

  async function loadCompatibleComponents(idpTypes) {
    try {
      return componentService
        .listComponents({ idpTypes })
        .then((components) =>
          components.filter((c) => c.componentId !== component.componentId)
        );
    } catch (err) {
      setError(err);
    }
  }

  async function requestDelete() {
    try {
      await componentService.deleteComponent(params.componentId);
      navigate('/components');
    } catch (err) {
      setError(err);
    }
  }

  useEffect(() => {
    if (!component) {
      loadComponent();
    }
  }, [component, loadComponent]);

  const tabs = [
    {
      label: t('component-details-title', { ns: 'component' }),
      value: 'general',
      children: (
        <GeneralComponentDetails
          component={component}
          updateComponent={updateComponent}
        />
      ),
    },
    {
      label: t('component-lifecycle-title', { ns: 'component' }),
      value: 'lifecycle',
      children: (
        <ComponentLifecycle
          component={component}
          updateComponent={updateComponent}
        />
      ),
    },
    {
      label: t('component-dependencies-title', { ns: 'component' }),
      value: 'dependencies',
      children: (
        <ComponentDependencies
          component={component}
          updateComponent={updateComponent}
          loadComponent={loadComponent}
          loadCompatibleComponents={loadCompatibleComponents}
        />
      ),
    },
    {
      label: t('component-settings-title', { ns: 'component' }),
      value: 'settings',
      children: (
        <ComponentSettingsSchema
          component={component}
          updateComponent={updateComponent}
        />
      ),
    },
  ];

  if (component?.componentType === 'app') {
    tabs.push({
      label: t('component-application-configuration-title', {
        ns: 'component',
      }),
      value: 'appConfiguration',
      children: (
        <AppComponentConfiguration
          component={component}
          updateComponent={updateComponent}
        />
      ),
    });
  }

  return (
    <>
      {component ? (
        <div>
          <BreadcrumbList>
            <RouterLink to="/">
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </RouterLink>
            <RouterLink to="/components">Components</RouterLink>
            <Paragraph href="">
              {component ? component.general.name : 'Loading'}
            </Paragraph>
          </BreadcrumbList>
          <div
            className="componentHeader"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Header>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Image
                  src={
                    component.general.logoUri
                      ? component.general.logoUri
                      : 'https://cdn.demo.okta.com/images/identicons-set-v2/mono/svg/icon-integrations-marketplace.svg'
                  }
                  alt={component.general.name}
                  size="small"
                  style={{ marginRight: '10px' }}
                />
                <div>
                  {component.general.name}
                  <Typography variant="subordinate">
                    <ComponentCategoryTypeLabel
                      category={component.general.category}
                      type={component.componentType}
                    />
                  </Typography>
                  <CollaboratorList
                    entity={component}
                    includeManage={true}
                    setOpen={setOpen}
                  />
                  <CollaborationModal
                    entity={component}
                    open={open}
                    setOpen={setOpen}
                    function_reloaddata={loadComponent}
                    entityType="component"
                  ></CollaborationModal>
                </div>
              </div>
            </Header>
            <div style={{ marginBottom: '10px', marginLeft: 'auto' }}>
              <Button
                label={t('button-delete-label', { ns: 'core' })}
                variant="danger"
                onClick={() => setDeleteDialogOpen(true)}
              />
            </div>
          </div>
          <Surface className="appComponent">
            <div className="appComponent">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.14285714rem',
                }}
              >
                {error && <ErrorMessage error={error} />}
                <Tabs value="general" tabs={tabs} />
              </Box>
            </div>
            <Dialog
              isOpen={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              title={t('deleteComponent-title', { ns: 'component' })}
              primaryCallToActionComponent={
                <Button
                  label={t('button-confirm-label', {
                    ns: 'core',
                  })}
                  variant="danger"
                  onClick={() => requestDelete()}
                />
              }
              secondaryCallToActionComponent={
                <Button
                  label={t('button-cancel-label', {
                    ns: 'core',
                  })}
                  variant="floating"
                  onClick={() => setDeleteDialogOpen(false)}
                />
              }
            >
              {t('deleteComponent-description', { ns: 'component' })}
            </Dialog>
          </Surface>
        </div>
      ) : (
        <Box>{error && <ErrorMessage error={error} />}</Box>
      )}
      {isLoading && <CircularProgress />}
    </>
  );
};

export default ComponentDetails;
