import React from 'react';
import Gravatar from 'gravatar';
import './CollaboratorList.css';
import { Avatar, Tooltip, Box } from '@mui/material';
import { SettingsIcon } from '@okta/odyssey-react-mui/icons';
import { Button } from '@okta/odyssey-react-mui';

function CollaboratorList({ entity, includeManage, setOpen }) {
  const firstFive = entity?.collaborators
    ? entity?.collaborators?.slice(0, 5)
    : [];
  const remainingCount = entity?.collaborators?.length - 5;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        gap: `${-30}px`,
      }}
    >
      {firstFive.map((collaborator, index) => (
        <Tooltip key={collaborator.email} title={collaborator.email} arrow>
          <Avatar
            alt={collaborator.email}
            src={Gravatar.url(collaborator.email, {
              protocol: 'https',
              s: '40',
              d: 'https://cdn.demo.okta.com/images/okta-icon.png',
            })}
            sx={{
              width: 30,
              height: 30,
              border: '2px solid white',
              zIndex: 5 - index,
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            }}
          />
        </Tooltip>
      ))}
      {remainingCount > 0 && <span>+{remainingCount} more</span>}
      {includeManage && entity.collaborators && (
        <Button
          startIcon={<SettingsIcon />}
          onClick={() => setOpen(true)}
          variant="floating"
          size="small"
        />
      )}
    </Box>
  );
}

export default CollaboratorList;
