import { Code } from '@mui/icons-material';
import { ChatIcon, ServerIcon } from '@okta/odyssey-react-mui/icons';
import { SideNav } from '@okta/odyssey-react-mui/labs';
import { useFlags } from 'flagsmith/react';
import { useTranslation } from 'react-i18next';

const SideNavigation = () => {
  const { t } = useTranslation(['core']);
  const flags = useFlags(['components', 'support']);

  const buildNavItems = [];
  if (flags.components.enabled) {
    buildNavItems.push({
      id: 'build-1',
      label: t('nav-side-build-component-label'),
    });
  } else {
    buildNavItems.push(
      ...[
        {
          id: 'build-1',
          label: t('nav-side-build-app-label'),
        },
        {
          id: 'build-2',
          label: t('nav-side-build-resource-label'),
        },
      ]
    );
  }
  buildNavItems.push({
    target: '_blank',
    href: 'https://docs.demo.okta.com/',
    id: 'build-3',
    label: t('nav-side-build-apidocs-label'),
  });

  const navigationItems = [
    {
      id: 'environments',
      label: t('nav-side-demo-section-label'),
      startIcon: <ServerIcon />,
    },
    {
      id: 'build',
      label: t('nav-side-build-section-label'),
      startIcon: <Code />,
      nestedNavItems: buildNavItems,
    },
  ];

  if (flags.support.enabled) {
    navigationItems.push({
      id: 'support',
      startIcon: <ChatIcon />,
      label: t('nav-side-support-section-label'),
      nestedNavItems: [
        {
          target: '_blank',
          href: 'https://okta.slack.com/archives/CE676UAUS',
          id: 'support-1',
          label: t('nav-side-support-slack-label'),
        },
        {
          target: '_blank',
          href: 'https://form.asana.com/?k=puvfnI_Q7cfzv9zrbAPA-A&d=10114792544943',
          id: 'support-2',
          label: t('nav-side-support-form-label'),
        },
      ],
    });
  }

  return <SideNav appName={t('app-name')} sideNavItems={navigationItems} />;
};

export default SideNavigation;
