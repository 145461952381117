import axios from 'axios';
import Config from '../Config';

export async function listApplications(accessToken, idpType) {
  return axios.get(Config.resourceServer.demoAPI + '/applications', {
    params: { supports_idp_type: idpType },
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

export async function createApplication(
  accessToken,
  name,
  baseAddress,
  discoveryMechanism,
  description,
  hooks,
  settings,
  dependencies
) {
  var data = JSON.stringify({
    name: name,
    baseAddress: baseAddress,
    multiTenancy: discoveryMechanism,
    description: description,
    hooks: hooks,
    settings: settings,
    dependsOn: dependencies,
  });

  var config = {
    method: 'post',
    url: `${Config.resourceServer.demoAPI}/applications`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  };
  return axios(config);
}

export async function getApplication(accessToken, appId) {
  return axios.get(`${Config.resourceServer.demoAPI}/applications/${appId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

export async function getApplicationMetrics(accessToken, appId) {
  return axios.get(
    `${Config.resourceServer.demoAPI}/applications/${appId}/metrics`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
}

export async function updateApplication(
  accessToken,
  appId,
  name,
  baseAddress,
  multiTenancy,
  description,
  hooks,
  settings,
  dependencies,
  documentationLink,
  supportLink,
  videoLink,
  logoLink,
  tags
) {
  var data = JSON.stringify({
    name: name,
    baseAddress: baseAddress,
    multiTenancy: multiTenancy,
    description: description,
    hooks: hooks,
    settings: settings,
    dependsOn: dependencies,
    documentationLink: documentationLink,
    supportLink: supportLink,
    logo: logoLink,
    video: videoLink,
    tags: tags,
  });

  var config = {
    method: 'put',
    url: `${Config.resourceServer.demoAPI}/applications/${appId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  };

  return axios(config);
}

export async function deleteApplication(accessToken, appId) {
  return axios.delete(
    `${Config.resourceServer.demoAPI}/applications/${appId}`,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
}

export async function listResources(accessToken, idpType) {
  return axios.get(Config.resourceServer.demoAPI + '/resources', {
    params: {
      supports_idp_type: idpType,
    },
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

export async function getResource(accessToken, id) {
  return axios.get(Config.resourceServer.demoAPI + '/resources/' + id, {
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  });
}

export async function getResourceMetrics(accessToken, resourceId) {
  return axios.get(
    `${Config.resourceServer.demoAPI}/resources/${resourceId}/metrics`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
}

export async function createResource(
  accessToken,
  name,
  description,
  hooks,
  settings,
  dependencies
) {
  var data = JSON.stringify({
    name: name,
    description: description,
    hooks: hooks,
    settings: settings,
    dependsOn: dependencies,
  });

  var config = {
    method: 'post',
    url: Config.resourceServer.demoAPI + '/resources',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  };

  return axios(config);
}

export async function updateResource(
  accessToken,
  resourceId,
  name,
  description,
  hooks,
  settings,
  dependencies,
  documentationLink,
  supportLink,
  videoLink,
  logoLink,
  tags
) {
  var data = JSON.stringify({
    name: name,
    description: description,
    hooks: hooks,
    settings: settings,
    dependsOn: dependencies,
    documentationLink: documentationLink,
    supportLink: supportLink,
    video: videoLink,
    logo: logoLink,
    tags: tags,
  });

  var config = {
    method: 'put',
    url: Config.resourceServer.demoAPI + '/resources/' + resourceId,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  };

  return axios(config);
}

export async function deleteResource(accessToken, resourceId) {
  return axios.delete(
    Config.resourceServer.demoAPI + '/resources/' + resourceId,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
}
