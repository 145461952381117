import { Button } from '@okta/odyssey-react-mui';
import { useUserPreferenceContext } from '../../UserPreferenceContext';
import { ExternalLinkIcon } from '@okta/odyssey-react-mui/icons';

const LaunchButton = ({
  url,
  isDisabled = false,
  label = 'Launch',
  className = 'branded',
  icon = 'external alternate',
}) => {
  const userPreference = useUserPreferenceContext();

  return (
    <Button
      startIcon={<ExternalLinkIcon />}
      className={className}
      onClick={() => window.open(userPreference.rewriteLaunchUrl(url))}
      label={label}
      isDisabled={isDisabled}
    />
  );
};

export default LaunchButton;
