import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import CategoryLabel from '../components/CategoryLabel';
import InstanceDetails from '../components/instance/InstanceDetails';
import { sortObjectByKeys } from '../util/SortObjectByKeys';
import {
  BreadcrumbList,
  Callout,
  CircularProgress,
  Surface,
  Button,
  Dialog,
  Typography,
  Link,
} from '@okta/odyssey-react-mui';
import { useNavigate } from 'react-router';
import Header from '../components/ui/Header/Header';
import { CheckIcon, CloseIcon, HomeIcon } from '@okta/odyssey-react-mui/icons';
import { useServiceContext } from '../context';
import { useTranslation } from 'react-i18next';
import Image from '../components/ui/Image/Image';
import StateLabel from '../components/StateLabel';
import LaunchButton from '../components/ui/LaunchButton';
import Skeleton from '@mui/material/Skeleton';
import InstanceLabel from '../components/instance/InstanceLabel';

const ComponentInstanceDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [instance, setInstance] = useState(null);
  const [label, setLabel] = useState();
  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [detachModalOpen, setDetachModalOpen] = useState(false);
  const { isAuthenticated } = useAuth0();
  const { componentInstanceService } = useServiceContext();
  const { t } = useTranslation(['core', 'component', 'demo']);

  const getComponentInstance = useCallback(async () => {
    setInstance();
    setLabel();
    if (componentInstanceService) {
      componentInstanceService
        .retrieveComponentInstance(params.demoName, params.componentId)
        .then((response) => {
          response.instanceDetails.settings = sortObjectByKeys(
            response.instanceDetails.settings || {}
          );
          setInstance(response);
          setLabel(response.instanceDetails.label);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [
    componentInstanceService,
    setInstance,
    setLabel,
    params.componentId,
    params.demoName,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      getComponentInstance();
    } else {
      setInstance();
      setLabel();
    }
  }, [
    componentInstanceService,
    getComponentInstance,
    isAuthenticated,
    setInstance,
  ]);

  async function detachInstance() {
    setWaiting(true);
    componentInstanceService
      .detachComponent(params.demoName, params.componentId)
      .then(() => {
        navigate('/demo/' + params.demoName);
        setWaiting(false);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  return (
    <>
      <div>
        <BreadcrumbList>
          <RouterLink to="/">
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </RouterLink>
          <RouterLink to="/demos">
            {t('demo-list-label', { ns: 'demo' })}
          </RouterLink>
          <RouterLink to={`/demo/${params.demoName}`}>
            {params.demoName}
          </RouterLink>
          <i href="">
            {instance ? instance.instanceDetails.label : params.componentId}
          </i>
        </BreadcrumbList>
      </div>
      {instance ? (
        <div>
          <div
            className="componentHeader"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Header>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Image
                  src={
                    instance.componentDetails.logoUri
                      ? instance.componentDetails.logoUri
                      : 'https://cdn.demo.okta.com/images/identicons-set-v2/mono/svg/icon-integrations-marketplace.svg'
                  }
                  alt={instance.componentDetails.name}
                  size="small"
                  style={{ marginRight: '10px' }}
                />
                <div>
                  <InstanceLabel instance={instance} />
                  <Typography variant="subordinate">
                    <span className="ComponentMetaRow">
                      {instance.componentDetails.documentationUri && (
                        <Link
                          href={instance.componentDetails.documentationUri}
                          target="_blank"
                        >
                          Documentation
                        </Link>
                      )}
                      {instance.componentDetails.videoUri && (
                        <Link
                          href={instance.componentDetails.videoUri}
                          target="_blank"
                        >
                          Video
                        </Link>
                      )}
                      {instance.componentDetails.supportLink && (
                        <Link
                          target="_blank"
                          href={instance.componentDetails.supportUri}
                        >
                          Support
                        </Link>
                      )}
                    </span>
                    <StateLabel state={instance.state} />
                  </Typography>
                </div>
              </div>
            </Header>
            <div style={{ marginBottom: '10px', marginLeft: 'auto' }}>
              {(instance.instanceDetails.launchUrl ||
                instance.instanceDetails.link) && (
                <LaunchButton
                  url={
                    instance.instanceDetailslaunchUrl
                      ? instance.instanceDetails.launchUrl
                      : instance.instanceDetails.link
                  }
                />
              )}
              <Button
                floated="right"
                isDisabled={waiting}
                type="button"
                color="red"
                variant="danger"
                label={t('button-detach-label', { ns: 'component' })}
                onClick={() => setDetachModalOpen(true)}
              >
                {t('button-detach-label', { ns: 'component' })}
              </Button>
              <Dialog
                onClose={() => setDetachModalOpen(false)}
                title={t('dialog-detach-text', {
                  ns: 'component',
                  componentLabel: label,
                })}
                onOpen={() => setDetachModalOpen(true)}
                isOpen={detachModalOpen}
                primaryCallToActionComponent={
                  <Button
                    variant="danger"
                    onClick={() => {
                      setDetachModalOpen(false);
                      detachInstance();
                    }}
                    label={t('button-detach-label', { ns: 'component' })}
                    startIcon={<CheckIcon />}
                  ></Button>
                }
                secondaryCallToActionComponent={
                  <Button
                    onClick={() => setDetachModalOpen(false)}
                    label={t('button-cancel-label', { ns: 'core' })}
                    variant="secondary"
                    startIcon={<CloseIcon />}
                  ></Button>
                }
              >
                {t('dialog-detach-description', {
                  ns: 'component',
                  componentLabel: label,
                })}
              </Dialog>
            </div>
          </div>
          <Surface className="appComponent">
            {waiting ? <CircularProgress active inline /> : null}
            {error ? <ErrorMessage error={error} /> : null}

            {instance.instanceDetails.deployedVersion <
              instance.componentDetails.version && (
              <Callout
                severity="info"
                title={t('callout-version-title', { ns: 'component' })}
                text={t('callout-version-description', { ns: 'component' })}
              />
            )}

            <CategoryLabel category={instance.category} />
            <InstanceDetails instance={instance} onUpdate={setInstance} />
          </Surface>
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Skeleton variant="rounded" width={80} height={80} />
            <Skeleton variant="text" width={200} height={80} />
          </div>
          <Surface></Surface>
        </>
      )}
    </>
  );
};
export default ComponentInstanceDetails;
