import { TextField, Button, Box } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { EditIcon } from '@okta/odyssey-react-mui/icons';

const EditableTextField = ({
  label,
  hint,
  value,
  onSave,
  onBlur,
  placeholder,
  errorMessage,
  multiline = false,
  type = 'text',
  isDisabled = false,
}) => {
  const { t } = useTranslation(['core', 'component', 'demo']);
  const [editedValue, setEditedValue] = useState(value);

  const [isEditMode, setIsEditMode] = useState(false);
  if (isEditMode) {
    return (
      <>
        <TextField
          label={label}
          hint={hint}
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
          onBlur={onBlur}
          placeholder={placeholder}
          errorMessage={errorMessage}
          isMultiline={multiline}
          type={type}
        />

        <Box sx={{ maxWidth: '55ch' }}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              variant="floating"
              label={t('button-cancel-label', { ns: 'core' })}
              onClick={() => setIsEditMode(false)}
            />
            <Button
              label={t('button-save-label', { ns: 'core' })}
              isDisabled={isDisabled}
              onClick={() => {
                onSave(editedValue);
                setIsEditMode(false);
              }}
            />
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <TextField
        label={label}
        hint={hint}
        isMultiline={multiline}
        placeholder={placeholder}
        isReadOnly
        value={value}
        endAdornment={
          <Button
            // label={t('button-edit-label', { ns: 'core' })}
            startIcon={<EditIcon />}
            size="small"
            variant="floating"
            onClick={() => {
              setEditedValue(value);
              setIsEditMode(true);
            }}
          />
        }
      />
    );
  }
};
export default EditableTextField;
