import axios from 'axios';

export function useStatusService() {
  async function getServiceStatus() {
    const res = axios.get(process.env.REACT_APP_SERVICE_MONITOR);
    return res.data;
  }

  return {
    getServiceStatus,
  };
}
