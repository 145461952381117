import { TopNav, UserProfile } from '@okta/odyssey-react-mui/labs';
import StatusBanner from '../StatusBanner';
import { Button } from '@okta/odyssey-react-mui';
import { SettingsIcon, UserIcon } from '@okta/odyssey-react-mui/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import flagsmith from 'flagsmith';

const TopNavigation = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  return (
    <TopNav
      leftSideComponent={<StatusBanner />}
      rightSideComponent={
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            onClick={() => navigate('/settings')}
            variant="floating"
            startIcon={<SettingsIcon />}
          />
          <UserProfile
            profileIcon={<UserIcon />}
            userName={user.name}
            orgName={flagsmith.getTrait('connection')?.toUpperCase()}
          />
        </div>
      }
    />
  );
};

export default TopNavigation;
