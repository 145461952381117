import { useOpportunityContext } from '../context';
import './OpportunityDetails.css';
import { useState } from 'react';
import OpportunityPicker from './OpportunityPicker';
import { useFlags } from 'flagsmith/react';
import Container from './ui/Container/Container';
import CloudIcon from '@mui/icons-material/Cloud';
import { Accordion } from '@okta/odyssey-react-mui';
import WorkIcon from '@mui/icons-material/Work';
const OpportunityDetails = ({
  linkedOpportunities,
  function_linkOpportunity,
  allowEdit,
}) => {
  const { opportunities } = useOpportunityContext();
  const [showDetail, setShowDetail] = useState(
    linkedOpportunities.length === 0 && opportunities.length > 0
  );
  const flags = useFlags(['opportunity_linking']);

  function handleClick() {
    setShowDetail(!showDetail);
  }

  function displayOpp(opp) {
    const i = opportunities.findIndex((e) => e.opportunity_id === opp);
    if (i > -1) {
      return (
        <div className="opportunityDataTableRow">
          <div className="opportunityDataTableCell">
            <a
              href={
                'https://okta.my.salesforce.com/' +
                opportunities[i].opportunity_id
              }
              target="_blank"
              rel="noreferrer"
            >
              <CloudIcon />
            </a>
          </div>
          <div className="opportunityDataTableCell">
            {opportunities[i].account_name}
          </div>
          <div className="opportunityDataTableCell">
            {opportunities[i].name}
          </div>
          <div className="opportunityDataTableCell">
            {opportunities[i].stage}
          </div>
          <div className="opportunityDataTableCell">
            {opportunities[i].user_role}
          </div>
        </div>
      );
    } else {
      return '';
    }
  }

  async function addOpp(event) {
    function_linkOpportunity(event.target.value);
  }

  if (flags.opportunity_linking.enabled || linkedOpportunities.length > 0) {
    return (
      <Accordion
        label={
          <>
            {' '}
            <WorkIcon /> Opportunity
          </>
        }
      >
        {linkedOpportunities.length > 0 && (
          <Container className="scrollable">
            <div className="opportunityDataTableTable">
              <div className="opportunityDataTableRow">
                <div className="opportunityDataTableCell headCell"></div>
                <div className="opportunityDataTableCell headCell">
                  Account Name
                </div>
                <div className="opportunityDataTableCell headCell">
                  Opportunity
                </div>
                <div className="opportunityDataTableCell headCell">Stage</div>
                <div className="opportunityDataTableCell headCell">Role</div>
              </div>
              {linkedOpportunities.map((opp) => displayOpp(opp))}
            </div>
          </Container>
        )}
        {flags.opportunity_linking.enabled && (
          <OpportunityPicker
            allowEdit={allowEdit}
            dismissOnChange={false}
            updateOpportunity={addOpp}
            existingOpps={linkedOpportunities}
          />
        )}
      </Accordion>
    );
  } else {
    return (
      <span>
        <WorkIcon /> Opportunity
      </span>
    );
  }
};
export default OpportunityDetails;
