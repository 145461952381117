import { useFlags } from 'flagsmith/react';
import React, { useEffect, useState } from 'react';
import Gravatar from 'gravatar';
import './CollaborationModal.css';
import ErrorMessage from '../ErrorMessage';
import {
  Button,
  CircularProgress,
  Dialog,
  TextField,
} from '@okta/odyssey-react-mui';
import Image from '../ui/Image/Image';
import { AddIcon, DeleteIcon } from '@okta/odyssey-react-mui/icons';
import { useServiceContext } from '../../context';

function CollaborationModal({
  entity,
  entityType,
  trigger,
  function_reloaddata,
  open,
  setOpen,
}) {
  const flags = useFlags(['collaboration']);
  const [hasChanges, setHasChanges] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [err, setErr] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const { collaborationService } = useServiceContext();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  useEffect(() => {
    if (open) {
      setErr(null);
    }
    if (!open) {
      if (hasChanges) {
        function_reloaddata();
        setHasChanges(false);
      }
    }
  }, [open, function_reloaddata, hasChanges]);

  async function addCollaborator(value) {
    setWaiting(true);
    switch (entityType) {
      case 'demonstration':
        try {
          await collaborationService.addDemoCollaborators(entity.name, {
            email: value,
            role: 'owner',
          });
          entity.collaborators.push({ email: value });
          document.getElementById('addCollaboratorInput').value = '';
          setHasChanges(true);
        } catch (err) {
          setErr(err);
        }

        break;
      case 'application':
        try {
          await collaborationService.addApplicationCollaborators(
            entity.application_id,
            {
              email: value,
              role: 'owner',
            }
          );
          entity.collaborators.push({ email: value });
          document.getElementById('addCollaboratorInput').value = '';
          setHasChanges(true);
        } catch (err) {
          setErr(err);
        }
        break;
      case 'component':
        try {
          await collaborationService.addComponentCollaborators(
            entity.componentId,
            {
              email: value,
              role: 'owner',
            }
          );
          entity.collaborators.push({ email: value });
          document.getElementById('addCollaboratorInput').value = '';
          setHasChanges(true);
        } catch (err) {
          setErr(err);
        }
        break;
      case 'resource':
        try {
          await collaborationService.addResourceCollaborators(
            entity.resource_id,
            {
              email: value,
              role: 'owner',
            }
          );
          entity.collaborators.push({ email: value });
          document.getElementById('addCollaboratorInput').value = '';
          setHasChanges(true);
        } catch (err) {
          setErr(err);
        }
        break;
      default:
    }
    setWaiting(false);
  }

  async function removeCollaborator(value) {
    setWaiting(true);
    switch (entityType) {
      case 'demonstration':
        try {
          await collaborationService.removeDemoCollaborators(entity.name, {
            email: value,
            role: 'owner',
          });
          entity.collaborators = entity.collaborators.filter(
            (item) => item.email !== value
          );
          setHasChanges(true);
        } catch (err) {
          setErr(err);
        }
        break;
      case 'application':
        try {
          await collaborationService.removeApplicationCollaborators(
            entity.application_id,
            {
              email: value,
              role: 'owner',
            }
          );
          entity.collaborators = entity.collaborators.filter(
            (item) => item.email !== value
          );
          setHasChanges(true);
        } catch (err) {
          setErr(err);
        }
        break;
      case 'component':
        try {
          await collaborationService.removeComponentCollaborators(
            entity.componentId,
            {
              email: value,
              role: 'owner',
            }
          );
          entity.collaborators = entity.collaborators.filter(
            (item) => item.email !== value
          );
          setHasChanges(true);
        } catch (err) {
          setErr(err);
        }
        break;
      case 'resource':
        try {
          await collaborationService.removeResourceCollaborators(
            entity.resource_id,
            {
              email: value,
              role: 'owner',
            }
          );
          entity.collaborators = entity.collaborators.filter(
            (item) => item.email !== value
          );
          setHasChanges(true);
        } catch (err) {
          setErr(err);
        }
        break;
      default:
    }
    setWaiting(false);
  }

  if (flags.collaboration.enabled && entity?.collaborators) {
    return (
      <Dialog
        title={`Collaborators on ${entity.name || entity?.general?.name}`}
        isOpen={open}
        onClose={() => setOpen(false)}
        primaryCallToActionComponent={
          <>
            {' '}
            {waiting && <CircularProgress />}
            <Button
              label="Done"
              onClick={() => setOpen(false)}
              isDisabled={waiting}
              variant="primary"
            />
          </>
        }
      >
        <div className="collaborationModal">
          <p>
            Users here will be able to see and modify <b>{entity.name}</b> in
            <a
              href={`https://builder.demo.okta.com`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              builder.demo.okta.com
            </a>
          </p>

          {/* Collaborators List */}
          {entity?.collaborators.map((collaborator) => (
            <div className="collaboratorContainer">
              <Image
                className="collaborator"
                size="tiny"
                src={Gravatar.url(collaborator.email, {
                  protocol: 'https',
                  s: '100',
                  d: 'https://cdn.demo.okta.com/images/okta-icon.png',
                })}
                avatar
              />
              {collaborator.email}
              {entity.collaborators.length > 1 && (
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => removeCollaborator(collaborator.email)}
                  size="small"
                  variant="floating"
                />
              )}
            </div>
          ))}
          <h4>Invite Collaborator</h4>
          <div className="fieldContainer">
            <TextField
              id="addCollaboratorInput"
              placeholder="Enter email"
              type="email"
              hint="Add a collaborator by entering their email address."
              isDisabled={waiting}
              errorMessage={errorMessage}
            />
            <Button
              startIcon={<AddIcon />}
              isDisabled={waiting}
              onClick={() => {
                const email = document.getElementById(
                  'addCollaboratorInput'
                ).value;
                if (emailRegex.test(email)) {
                  setErrorMessage(null);
                  addCollaborator(email);
                } else {
                  setErrorMessage('Invalid Email');
                }
              }}
            />
          </div>
          {err && <ErrorMessage error={err}>{err}</ErrorMessage>}
        </div>
      </Dialog>
    );
  } else {
    return <span>{trigger}</span>;
  }
}

export default CollaborationModal;
