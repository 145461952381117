import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useServiceContext } from './ServiceContext';

const DemoContext = createContext({
  demos: {},
  idps: {},
  loading: {},
  error: {},
  refreshContext: () => {},
});

const DemoContextProvider = ({ children }) => {
  const [error, setError] = useState();
  const [demos, setDemos] = useState([]);
  const [idps, setIdps] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated } = useAuth0();
  const { identityProviderService, demoService } = useServiceContext();

  const refreshContext = async () => {
    setError();
    setLoading(true);
    if (isAuthenticated) {
      try {
        let demoData = await demoService.listDemos();
        demoData.sort((a, b) => a.label.localeCompare(b.label));
        setDemos(demoData);
        const idpData = await identityProviderService.listIdentityProviders();
        setIdps(idpData);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    } else {
      setDemos([]);
      setIdps([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && demoService && identityProviderService) {
      refreshContext();
    } else {
      setDemos([]);
      setIdps([]);
    }
  }, [
    isAuthenticated,
    setDemos,
    setIdps,
    demoService,
    identityProviderService,
  ]);

  return (
    <DemoContext.Provider
      value={{ demos, idps, loading, error, refreshContext }}
    >
      {children}
    </DemoContext.Provider>
  );
};
export const useDemoContext = () => useContext(DemoContext);
export default DemoContextProvider;
