import { Link, TagList } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';
import ComponentTag from './ComponentTag';

const ComponentLinks = (docs, support, video, tags = []) => {
  const { t } = useTranslation(['core', 'component', 'demo']);

  return (
    <div className="buttonContainerTooltip">
      <div className="docLinks">
        {docs && (
          <Link target="_blank" rel="noreferrer" href={docs}>
            {t('documentation-link-label', { ns: 'component' })}
          </Link>
        )}
        {video && (
          <Link target="_blank" rel="noreferrer" href={video}>
            {t('video-link-label', { ns: 'component' })}
          </Link>
        )}
        {support && (
          <Link target="_blank" rel="noreferrer" href={support}>
            {t('support-link-label', { ns: 'component' })}
          </Link>
        )}
        {tags.length > 0 && (
          <TagList>
            {tags.map((tag) => (
              <ComponentTag tag={tag} />
            ))}
          </TagList>
        )}
      </div>
    </div>
  );
};

export default ComponentLinks;
