import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'flagsmith/react';
import { useServiceContext } from './ServiceContext';

const OpportunityContext = createContext({
  opportunities: {},
  setOpportunities: () => {},
});

const OpportunityContextProvider = ({ children }) => {
  const { opportunityService } = useServiceContext();
  const [opportunities, setOpportunities] = useState();
  const { isAuthenticated } = useAuth0();
  const flags = useFlags(['opportunity_linking']);
  useEffect(() => {
    if (isAuthenticated && flags.opportunity_linking.enabled) {
      async function loadOpportunities() {
        try {
          let opportunities = await opportunityService.listOpportunities();
          setOpportunities(opportunities);
        } catch (err) {
          setOpportunities([]);
        }
      }
      loadOpportunities();
    } else {
      setOpportunities([]);
    }
  }, [
    isAuthenticated,
    setOpportunities,
    flags.opportunity_linking.enabled,
    opportunityService,
  ]);

  return (
    <OpportunityContext.Provider value={{ opportunities, setOpportunities }}>
      {children}
    </OpportunityContext.Provider>
  );
};
export const useOpportunityContext = () => useContext(OpportunityContext);
export default OpportunityContextProvider;
