import React from 'react';
// eslint-disable-next-line
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@okta/odyssey-react-mui';
import './App.css';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './components/Themes';
import { useTheme } from './components/ThemeController';
import flagsmith from 'flagsmith';
import {
  DemoContextProvider,
  OpportunityContextProvider,
  ServiceContextProvider,
} from './context';
import UserPreferenceContextProvider from './UserPreferenceContext';
import Heropa from './components/Heropa';
import { OdysseyThemeProvider } from '@okta/odyssey-react-mui';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import Applications from './components/Applications';
import Resources from './components/Resources';
import LibraryComponentDetails from './components/library/LibraryComponentDetails';
import {
  Demos,
  DemoComponents,
  DemoDetails,
  TemplateDeploy,
  Settings,
  LibraryDemonstrationCreate,
  CreateApplication,
  CreateResource,
  CreateDemo,
  ResourceDetails,
  ApplicationDetails,
  EditComponent,
  Components,
  CreateComponent,
  ComponentInstanceDetails,
} from './routes';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import {
  FooterNavigation,
  SideNavigation,
  TopNavigation,
} from './components/navigation';
function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect, user } =
    useAuth0();
  const navigate = useNavigate();
  const [theme] = useTheme();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;
  // Temporary workaround to circumvent page refresh issue of sidenav. To be reverted when fix is released by Odyssey.
  function handleNavigation(e) {
    if (!e.target) return;

    let textToCheck = e.target.innerText;
    if (
      e.target.children &&
      e.target.children.length > 0 &&
      e.target.children[0] &&
      !textToCheck
    ) {
      textToCheck = e.target.children[0].innerText;
    }
    switch (textToCheck) {
      case 'Applications':
        navigate('/apps');
        break;
      case 'Resources':
        navigate('/resources');
        break;
      case 'Components':
        navigate('/components');
        break;
      case 'Environments':
        navigate('/');
        break;
      case 'Create New Environment':
        navigate('/demo');
        break;
      default:
        break;
    }
  }

  if (isLoading) {
    return (
      <div className="loadingContainer">
        <CircularProgress color="inherit" label="Loading" />
        <span>Loading</span>
      </div>
    );
  }
  if (error) {
    window.location.replace(
      `${process.env.REACT_APP_ERROR_URL}?client_id=${process.env.REACT_APP_CLIENT_ID}&error=${error.name}&error_description=${error.message}`
    );
    return null;
  }

  if (isAuthenticated) {
    flagsmith.identify(user.email, {
      connection:
        user['https://' + process.env.REACT_APP_DOMAIN + '/connection/'],
    });
    return (
      <ThemeProvider theme={themeMode}>
        <I18nextProvider i18n={i18n}>
          <OdysseyThemeProvider>
            <ServiceContextProvider>
              <UserPreferenceContextProvider>
                <OpportunityContextProvider>
                  <DemoContextProvider>
                    <div
                      style={{ display: 'flex', flexDirection: 'row' }}
                      onClick={handleNavigation}
                    >
                      <SideNavigation />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: '1',
                          backgroundColor: 'rgb(244, 244, 244)',
                          minHeight: '100vh',
                        }}
                      >
                        <TopNavigation />
                        <Container
                          sx={{
                            display: 'flex',
                            flexGrow: '1',
                            flexDirection: 'column',
                          }}
                        >
                          <Routes>
                            <Route path="/" exact element={<Demos />} />
                            <Route path="/demos" exact element={<Demos />} />
                            <Route
                              path="/settings"
                              exact
                              element={<Settings />}
                            />
                            <Route
                              path="/demo"
                              exact
                              element={<CreateDemo />}
                            />
                            <Route
                              path="/demo/:demoName"
                              element={<DemoDetails />}
                            />
                            <Route
                              path="/demo/:demoName/components"
                              element={<DemoComponents />}
                            />
                            <Route
                              path="/demo/:demoName/components/:componentId"
                              element={<ComponentInstanceDetails />}
                            />
                            <Route
                              path="/apps"
                              exact
                              element={<Applications />}
                            />
                            <Route
                              path="/app"
                              exact
                              element={<CreateApplication />}
                            />
                            <Route
                              path="/app/:appId"
                              element={<ApplicationDetails />}
                            />
                            <Route
                              path="/components"
                              exact
                              element={<Components />}
                            />
                            <Route
                              path="/component"
                              exact
                              element={<CreateComponent />}
                            />
                            <Route
                              path="/components/:componentId"
                              element={<EditComponent />}
                            />
                            <Route
                              path="/resources"
                              exact
                              element={<Resources />}
                            />
                            <Route
                              path="/resource"
                              exact
                              element={<CreateResource />}
                            />
                            <Route
                              path="/resource/:resourceId"
                              element={<ResourceDetails />}
                            />
                            <Route
                              path="/library/:appId/create"
                              element={<LibraryDemonstrationCreate />}
                            />
                            <Route
                              path="/library/:id"
                              element={<LibraryComponentDetails />}
                            />
                            <Route
                              path="/template"
                              element={<TemplateDeploy />}
                            />
                            <Route
                              path="/template/:template"
                              element={<TemplateDeploy />}
                            />
                            <Route path="/vm" exact element={<Heropa />} />
                          </Routes>
                        </Container>
                        <FooterNavigation />
                      </div>
                    </div>
                  </DemoContextProvider>
                </OpportunityContextProvider>
              </UserPreferenceContextProvider>
            </ServiceContextProvider>
          </OdysseyThemeProvider>
        </I18nextProvider>
      </ThemeProvider>
    );
  } else {
    loginWithRedirect({ appState: { targetUrl: window.location } });
  }
}

export default App;
