import React, { useState } from 'react';

import Header from '../ui/Header/Header';
import { Button, TextField } from '@okta/odyssey-react-mui';
import { EditIcon } from '@okta/odyssey-react-mui/icons';
import { useServiceContext } from '../../context';

export const InstanceLabel = ({ instance }) => {
  const [waiting, setWaiting] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [label, setLabel] = useState(instance?.instanceDetails?.label);
  const { componentInstanceService } = useServiceContext();

  function handleChange(event) {
    setLabel(event.target.value);
  }

  return (
    <Header className="contentHeader">
      {!editMode ? (
        <>
          <span>
            {label}{' '}
            <Button
              startIcon={<EditIcon />}
              className="inlineIconControl"
              name="edit"
              size="small"
              variant="floating"
              onClick={() => {
                setEditMode(true);
              }}
            />
          </span>

          {label !== instance?.componentDetails?.name && (
            <Header.Subheader>
              {instance?.componentDetails?.name}
            </Header.Subheader>
          )}
        </>
      ) : (
        <>
          <TextField
            isDisabled={waiting}
            name="label"
            className="inlineInput"
            value={label}
            onChange={handleChange}
          >
            <input maxLength="64" />
          </TextField>
          <Button
            isDisabled={waiting}
            className="branded"
            label="Update"
            style={{ fontSize: '1rem', verticalAlign: 'text-top' }}
            onClick={async () => {
              setWaiting(true);
              try {
                await componentInstanceService.updateComponentInstance(
                  instance.demoName,
                  instance.componentId,
                  { label }
                );
              } finally {
                setEditMode(false);
                setWaiting(false);
              }
            }}
          ></Button>
          <Button
            isDisabled={waiting}
            label="Cancel"
            variant="secondary"
            style={{ fontSize: '1rem', verticalAlign: 'text-top' }}
            onClick={() => {
              setLabel(
                instance?.instanceDetails?.label ||
                  instance?.componentDetails?.name
              );
              setEditMode(false);
            }}
          ></Button>
        </>
      )}
    </Header>
  );
};

export default InstanceLabel;
