import React, { useState } from 'react';
import { Autocomplete } from '@okta/odyssey-react-mui';

const DemoList = ({ demos, selectionChanged }) => {
  const [selected, setSelected] = useState(null);

  function buildOptions(demos) {
    const options = [];
    demos.forEach((element) => {
      options.push({
        id: element.name,
        label: element.label,
        value: element.name,
      });
    });
    return options;
  }

  return (
    <Autocomplete
      label="Demonstration"
      options={buildOptions(demos)}
      onChange={(event, arg) => {
        const { label = null, value = null } = arg || {};
        if (label && value) {
          setSelected({ label: label, value });
          selectionChanged(value);
        } else {
          setSelected(null);
          selectionChanged(null);
        }
      }}
      value={selected}
    />
  );
};

export default DemoList;
