import {
  Box,
  Button,
  CheckboxGroup,
  Fieldset,
  Form,
  Heading1,
  Select,
  Surface,
  Switch,
  TextField,
} from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';
import { ComponentHookField } from '../components/fields';
import { DemonstrationDiscoveryOptions } from '../components/DemonstrationDiscoveryConstants';
import { useAuth0 } from '@auth0/auth0-react';
import { ComponentTypeOptions } from '../components/constants';
import './../i18n';
import { useState } from 'react';

import ErrorMessage from '../components/ErrorMessage';
import NavigationButton from '../components/NavigationButton';
import { useServiceContext } from '../context';
import { useNavigate } from 'react-router-dom';

const CreateComponent = () => {
  const { user } = useAuth0();
  const { t } = useTranslation(['core', 'component', 'demo']);
  const [componentType, setComponentType] = useState('app');
  const [name, setName] = useState();
  const [nameError, setNameError] = useState();
  const [description, setDescription] = useState();
  const [descriptionError, setDescriptionError] = useState();
  const [version, setVersion] = useState();
  const [supportLink, setSupportLink] = useState();
  const [documentationLink, setDocumentationLink] = useState();
  const [videoLink, setVideoLink] = useState();
  const [logoLink, setLogoLink] = useState();
  const [supportLinkError, setSupportLinkError] = useState();
  const [documentationLinkError, setDocumentationLinkError] = useState();
  const [videoLinkError, setVideoLinkError] = useState();
  const [logoLinkError, setLogoLinkError] = useState();
  const [expiresAfter, setExpiresAfter] = useState();
  const [requestHook, setRequestHook] = useState();
  const [requestHookError, setRequestHookError] = useState();
  const [attachHook, setAttachHook] = useState();
  const [attachHookError, setAttachHookError] = useState();
  const [updateHook, setUpdateHook] = useState();
  const [updateHookError, setUpdateHookError] = useState();
  const [executeHook, setExecuteHook] = useState();
  const [executeHookError, setExecuteHookError] = useState();
  const [detachHook, setDetachHook] = useState();
  const [detachHookError, setDetachHookError] = useState();
  const [allHooksError, setAllHooksError] = useState();
  const [error, setError] = useState();
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const [idpDependencies, setIdpDependencies] = useState([]);
  const [demoDependencies, setDemoDependencies] = useState([]);
  const [baseAddress, setBaseAddress] = useState();
  const [baseAddressError, setBaseAddressError] = useState();

  const [multiTenancy, setMultiTenancy] = useState('subdomain');
  const [tags, setTags] = useState([]);
  const { componentService } = useServiceContext();
  const navigate = useNavigate();

  function isOptionalEqualToValue(tagOption, value) {
    return tagOption.id === value;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let payload = {
        general: {
          name,
          description,
          documentationUri: documentationLink,
          supportUri: supportLink,
          tags: tags,
          category: 'private',
          logoUri: logoLink,
          videoUri: videoLink,
          version: version || '1.0.0',
        },
        componentType: componentType,
        dependsOn: {
          idp: {
            types: idpDependencies,
          },
          demo: {
            types: demoDependencies,
          },
        },
        lifecycle: {
          expiresAfter: expiresAfter ? Number(expiresAfter) : undefined,
          hooks: {
            request: requestHook,
            attach: attachHook,
            update: updateHook,
            detach: detachHook,
            execute: executeHook,
          },
        },
        settings: JSON.stringify(
          {
            $id: `https://demo.okta.com/components/${name}.settings.schema`,
            $schema: 'https://json-schema.org/draft/2020-12/schema',
            title: `${name} Configuration`,
            type: 'object',
            additionalProperties: false,
          },
          null,
          2
        ),
        collaborators: [
          {
            email: user.email,
            role: 'owner',
          },
        ],
        applicationConfiguration: {
          baseAddress: baseAddress,
          multiTenancy: multiTenancy,
        },
      };

      const {
        general,
        dependsOn,
        lifecycle,
        settings,
        collaborators,
        applicationConfiguration,
      } = payload;
      try {
        const component = await componentService.createComponent(
          general,
          lifecycle,
          settings,
          dependsOn,
          componentType,
          applicationConfiguration,
          collaborators
        );
        navigate('/components/' + component.componentId, { replace: true });
      } catch (err) {
        setError(err);
      }
    }
  };
  function validateForm() {
    const validURlregex =
      '((https?)://)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
    var result = true;
    if (!name) {
      setNameError(t('field-name-error-invalid', { ns: 'component' }));
      result = false;
    }
    if (!description) {
      setDescriptionError(
        t('field-description-error-invalid', { ns: 'component' })
      );
      result = false;
    }
    if (idpDependencies.length === 0) {
      setError(t('field-idpDependencies-error-invalid', { ns: 'component' }));
    } else {
      setError();
    }
    if (requestHook && !requestHook.match(validURlregex)) {
      setRequestHookError(
        t('field-requestHook-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setRequestHookError();
    }
    if (attachHook && !attachHook.match(validURlregex)) {
      setAttachHookError(
        t('field-attachHook-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setAttachHookError();
    }
    if (updateHook && !updateHook.match(validURlregex)) {
      setUpdateHookError(
        t('field-updateHook-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setUpdateHookError();
    }
    if (executeHook && !executeHook.match(validURlregex)) {
      setExecuteHookError(
        t('field-executeHook-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setExecuteHookError();
    }
    if (detachHook && !detachHook.match(validURlregex)) {
      setDetachHookError(
        t('field-detachHook-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setDetachHookError();
    }
    if (documentationLink && !documentationLink.match(validURlregex)) {
      setDocumentationLinkError(
        t('field-url-error-invalid', { ns: 'component' })
      );
      result = false;
    } else {
      setDocumentationLinkError();
    }
    if (logoLink && !logoLink.match(validURlregex)) {
      setLogoLinkError(t('field-url-error-invalid', { ns: 'component' }));
      result = false;
    } else {
      setLogoLinkError();
    }
    if (videoLink && !videoLink.match(validURlregex)) {
      setVideoLinkError(t('field-url-error-invalid', { ns: 'component' }));
      result = false;
    } else {
      setVideoLinkError();
    }
    if (supportLink && !supportLink.match(validURlregex)) {
      setSupportLinkError(t('field-url-error-invalid', { ns: 'component' }));
      result = false;
    } else {
      setSupportLinkError();
    }
    if (baseAddress && !baseAddress.match(validURlregex)) {
      setBaseAddressError(t('field-url-error-invalid', { ns: 'component' }));
      result = false;
    } else {
      setBaseAddressError();
    }
    if (
      !requestHook &&
      !attachHook &&
      !updateHook &&
      !detachHook &&
      !executeHook
    ) {
      setAllHooksError(t('field-allHooks-error-missing', { ns: 'component' }));
    } else {
      setAllHooksError();
    }
    return result;
  }
  function handleChange(event) {
    switch (
      event.target?.id?.length > 0
        ? event.target.id
        : event.target?.name || event.value
    ) {
      case 'name':
        setName(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      case 'componentType':
        setComponentType(event.target.value);
        break;
      case 'version':
        setVersion(event.target.value);
        break;
      case 'supportLink':
        setSupportLink(event.target.value);
        break;
      case 'documentationLink':
        setDocumentationLink(event.target.value);
        break;
      case 'videoLink':
        setVideoLink(event.target.value);
        break;
      case 'logoLink':
        setLogoLink(event.target.value);
        break;
      case 'tags':
        setTags(event.target.value);
        break;
      case 'expiresAfter':
        setExpiresAfter(event.target.value);
        break;
      case 'requestHook':
        setRequestHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'requestHookError':
        setRequestHookError(event.target.value);
        break;
      case 'attachHook':
        setAttachHook(event.target.value);
        break;
      case 'attachHookError':
        setAttachHookError(event.target.value);
        break;
      case 'updateHook':
        setUpdateHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'updateHookError':
        setUpdateHookError(event.target.value);
        break;
      case 'executeHook':
        setExecuteHook(event.target.value);
        break;
      case 'executeHookError':
        setExecuteHookError(event.target.value);
        break;
      case 'detachHook':
        setDetachHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'detachHookError':
        setDetachHookError(event.target.value);
        break;
      case 'allHooksError':
        setAllHooksError(event.target.value);
        break;
      case 'isAdvancedMode':
        setIsAdvancedMode(event.checked);
        break;
      case 'workforce-identity':
        if (event.checked) {
          setIdpDependencies((prevState) => {
            let set = new Set(prevState);
            set.add(event.value);
            return Array.from(set);
          });
        } else {
          setIdpDependencies((prevState) => {
            let set = new Set(prevState);
            set.delete(event.value);
            return Array.from(set);
          });
        }
        break;
      case 'customer-identity':
        if (event.checked) {
          setIdpDependencies((prevState) => {
            let set = new Set(prevState);
            set.add(event.value);
            return Array.from(set);
          });
        } else {
          setIdpDependencies((prevState) => {
            let set = new Set(prevState);
            set.delete(event.value);
            return Array.from(set);
          });
        }
        break;
      case 'okta-customer-identity':
        if (event.checked) {
          setIdpDependencies((prevState) => {
            let set = new Set(prevState);
            set.add(event.value);
            return Array.from(set);
          });
        } else {
          setIdpDependencies((prevState) => {
            let set = new Set(prevState);
            set.delete(event.value);
            return Array.from(set);
          });
        }
        break;
      case 'opportunity':
        if (event.checked) {
          setDemoDependencies((prevState) => {
            let set = new Set(prevState);
            set.add(event.value);
            return Array.from(set);
          });
        } else {
          setDemoDependencies((prevState) => {
            let set = new Set(prevState);
            set.delete(event.value);
            return Array.from(set);
          });
        }
        break;
      case 'enablement':
        if (event.checked) {
          setDemoDependencies((prevState) => {
            let set = new Set(prevState);
            set.add(event.value);
            return Array.from(set);
          });
        } else {
          setDemoDependencies((prevState) => {
            let set = new Set(prevState);
            set.delete(event.value);
            return Array.from(set);
          });
        }
        break;
      case 'labs':
        if (event.checked) {
          setDemoDependencies((prevState) => {
            let set = new Set(prevState);
            set.add(event.value);
            return Array.from(set);
          });
        } else {
          setDemoDependencies((prevState) => {
            let set = new Set(prevState);
            set.delete(event.value);
            return Array.from(set);
          });
        }
        break;
      case 'baseAddress':
        setBaseAddress(event.target.value);
        break;
      case 'multiTenancy':
        setMultiTenancy(event.target.value);
        break;

      default:
        break;
    }
  }
  return (
    <Surface>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <NavigationButton destination="/components" />
        <Heading1>Create new component</Heading1>
        {error && <ErrorMessage error={error} />}
        <Form
          className="fieldsetContainer"
          noValidate
          onSubmit={handleSubmit}
          formActions={
            <>
              <Button
                className="branded"
                type="submit"
                label={t('button-create-label', { ns: 'core' })}
              ></Button>
            </>
          }
        >
          <Fieldset
            legend={t('fieldset-general-legend', { ns: 'component' })}
            description={t('fieldset-general-description', { ns: 'component' })}
          >
            <Select
              label={t('field-type-label', { ns: 'component' })}
              hint={t('field-type-hint', { ns: 'component' })}
              defaultValue={'app'}
              id="componentType"
              onChange={handleChange}
              value={componentType}
              isOptional={false}
              options={ComponentTypeOptions()}
            />
            <TextField
              label={t('field-name-label', { ns: 'component' })}
              id="name"
              errorMessage={nameError}
              onChange={handleChange}
              value={name}
              hint={t('field-name-hint', { ns: 'component' })}
            />
            <TextField
              label={t('field-description-label', { ns: 'component' })}
              id="description"
              value={description}
              errorMessage={descriptionError}
              onChange={handleChange}
              hint={t('field-description-hint', { ns: 'component' })}
              multiline={true}
            />
            <TextField
              label={t('field-version-label', { ns: 'component' })}
              id="version"
              value={version}
              onChange={handleChange}
              hint={t('field-version-hint', { ns: 'component' })}
              defaultValue="1.0.0"
            />
            {/* <TextField
              label={t('field-supportLink-label', { ns: 'component' })}
              id="supportLink"
              value={supportLink}
              errorMessage={supportLinkError}
              onChange={handleChange}
              hint={t('field-supportLink-hint', { ns: 'component' })}
              type={'url'}
              isOptional
            />
            <TextField
              label={t('field-documentationLink-label', { ns: 'component' })}
              id="documentationLink"
              value={documentationLink}
              errorMessage={documentationLinkError}
              onChange={handleChange}
              hint={t('field-documentationLink-hint', { ns: 'component' })}
              type={'url'}
              isOptional
            />
            <TextField
              label={t('field-videoLink-label', { ns: 'component' })}
              id="videoLink"
              value={videoLink}
              errorMessage={videoLinkError}
              onChange={handleChange}
              hint={t('field-videoLink-hint', { ns: 'component' })}
              type={'url'}
              isOptional
            />
            <TextField
              label={t('field-logoLink-label', { ns: 'component' })}
              id="logoLink"
              value={logoLink}
              errorMessage={logoLinkError}
              onChange={handleChange}
              hint={t('field-logoLink-hint', { ns: 'component' })}
              type={'url'}
              isOptional
            />
            <Autocomplete
              hasMultipleChoices
              id="tags"
              isOptional
              hint={t('field-tags-hint', { ns: 'component' })}
              label={t('field-tags-label', { ns: 'component' })}
              onChange={handleChange}
              getIsOptionEqualToValue={isOptionalEqualToValue}
              options={ComponentTagOptions()}
              value={tags}
            /> */}
          </Fieldset>
          {componentType === 'app' && (
            <Fieldset
              description={t('fieldset-applicationConfiguration-description', {
                ns: 'component',
              })}
              legend={t('fieldset-applicationConfiguration-legend', {
                ns: 'component',
              })}
            >
              <TextField
                label={t('field-baseAddress-label', { ns: 'component' })}
                hint={t('field-baseAddress-hint', { ns: 'component' })}
                id="baseAddress"
                onChange={handleChange}
                errorMessage={baseAddressError}
                value={baseAddress}
                isOptional={false}
                type={'url'}
              />
              <Select
                label={t('field-multiTenancy-label', { ns: 'component' })}
                hint={t('field-multiTenancy-hint', { ns: 'component' })}
                options={DemonstrationDiscoveryOptions}
                onChange={handleChange}
                id="multiTenancy"
                value={multiTenancy}
                isOptional={false}
                defaultValue={'subdomain'}
              />
            </Fieldset>
          )}
          {/* <Fieldset
            legend={t('fieldset-lifecycle-legend', { ns: 'component' })}
            description={t('fieldset-lifecycle-description', {
              ns: 'component',
            })}
          >
            <TextField
              label={t('field-expiresAfter-label', { ns: 'component' })}
              id="expiresAfter"
              value={expiresAfter}
              onChange={handleChange}
              hint={t('field-expiresAfter-hint', { ns: 'component' })}
              type={'number'}
              isOptional
            /> */}
          <Fieldset
            description={t('fieldset-hooks-description', { ns: 'component' })}
            legend={t('fieldset-hooks-legend', { ns: 'component' })}
          >
            <ComponentHookField
              componentType={componentType}
              requestHook={requestHook}
              setRequestHook={setRequestHook}
              requestHookError={requestHookError}
              setRequestHookError={setRequestHookError}
              attachHook={attachHook}
              setAttachHook={setAttachHook}
              attachHookError={attachHookError}
              setAttachHookError={setAttachHookError}
              updateHook={updateHook}
              setUpdateHook={setUpdateHook}
              updateHookError={updateHookError}
              setUpdateHookError={setUpdateHookError}
              executeHook={executeHook}
              setExecuteHook={setExecuteHook}
              executeHookError={executeHookError}
              setExecuteHookError={setExecuteHookError}
              detachHook={detachHook}
              setDetachHook={setDetachHook}
              detachHookError={detachHookError}
              setDetachHookError={setDetachHookError}
              allHooksError={allHooksError}
              setAllHooksError={setAllHooksError}
              isAdvancedMode={isAdvancedMode}
              setIsAdvancedMode={setIsAdvancedMode}
            />
          </Fieldset>
          {/* </Fieldset> */}
          <Fieldset
            legend={t('fieldset-dependency-legend', { ns: 'component' })}
            description={t('fieldset-dependency-description', {
              ns: 'component',
            })}
          >
            <CheckboxGroup
              hint={t('fieldset-idpDependency-hint', {
                ns: 'component',
              })}
              id="idpDependencies"
              onChange={handleChange}
              label={t('fieldset-idpDependency-label', { ns: 'component' })}
              isRequired={true}
            >
              <Switch
                label={t('field-idpDependency-workforce-label', {
                  ns: 'component',
                })}
                onChange={handleChange}
                id="idpDependencies"
                name="workforce-identity"
                value="workforce-identity"
              />
              <Switch
                label={t('field-idpDependency-customer-label', {
                  ns: 'component',
                })}
                id="customer-identity"
                name="customer-identity"
                value="customer-identity"
                onChange={handleChange}
              />
              <Switch
                label={t('field-idpDependency-okta-customer-label', {
                  ns: 'component',
                })}
                id="okta-customer-identity"
                name="okta-customer-identity"
                value="okta-customer-identity"
                onChange={handleChange}
              />
            </CheckboxGroup>
            {/* <CheckboxGroup
              hint={t('fieldset-demoDependency-hint', {
                ns: 'component',
              })}
              label={t('fieldset-demoDependency-label', { ns: 'component' })}
            >
              <Switch
                label={t('field-demoDependency-opportunity-label', {
                  ns: 'component',
                })}
                name="opportunity"
                id="opportunity"
                onChange={handleChange}
                value="opportunity"
              />
              <Switch
                label={t('field-demoDependency-enablement-label', {
                  ns: 'component',
                })}
                name="enablement"
                id="enablement"
                onChange={handleChange}
                value="enablement"
              />
              <Switch
                label={t('field-demoDependency-lab-label', { ns: 'component' })}
                name="lab"
                id="lab"
                onChange={handleChange}
                value="lab"
              />
            </CheckboxGroup>
            <Fieldset
              description={t('fieldset-componentDependency-description', {
                ns: 'component',
              })}
              legend={t('fieldset-componentDependency-legend', {
                ns: 'component',
              })}
            ></Fieldset> */}
          </Fieldset>
        </Form>
      </Box>
    </Surface>
  );
};

export default CreateComponent;
