export function useDemonstrationService(client) {
  if (!client) return null;
  async function createDemo(name, type, idpId, label, source = 'builder') {
    // 'x-creation-source': source;
    const res = await client.createDemonstration(null, {
      name: name,
      label,
      type: type,
      idp_id: idpId,
    });
    return res.data;
  }

  async function linkDemoToOpportunity(demoName, opportunityId) {
    const res = await client.updateDemonstration(demoName, {
      opportunity_id: opportunityId,
    });
    return res.data;
  }

  async function updateDemoLabel(demoName, label) {
    const res = await client.updateDemonstration(demoName, {
      label,
    });
    return res.data;
  }

  async function convertEnablementDemoToOpportunity(demoName) {
    const res = await client.updateDemonstration(demoName, {
      type: 'opportunity',
    });
    return res.data;
  }

  async function getDemo(demoName) {
    const res = await client.retrieveDemonstration(demoName);
    return res.data;
  }

  async function deleteDemo(demoName) {
    const res = await client.deleteDemonstration(demoName);
    return res.data;
  }

  async function listDemos() {
    const res = await client.listDemonstration();
    return res.data;
  }

  return {
    listDemos,
    createDemo,
    getDemo,
    linkDemoToOpportunity,
    convertEnablementDemoToOpportunity,
    updateDemoLabel,
    deleteDemo,
  };
}
