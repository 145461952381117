import { Box, Fieldset } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';
import { EditableTextField } from '../fields';

function ComponentLifecycle({ component, updateComponent }) {
  const { t } = useTranslation(['core', 'component', 'demo']);
  const { componentType } = component;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <EditableTextField
        label={t('field-expiresAfter-label', { ns: 'component' })}
        hint={t('field-expiresAfter-hint', { ns: 'component' })}
        value={component?.lifecycle?.expiresAfter}
        type={'number'}
        onSave={(value) =>
          updateComponent({
            componentType,
            lifecycle: { expiresAfter: value },
          })
        }
      />
      <Fieldset
        description={t('fieldset-hooks-description', { ns: 'component' })}
        legend={t('fieldset-hooks-legend', { ns: 'component' })}
      >
        <EditableTextField
          label={t('field-requestHook-label', { ns: 'component' })}
          hint={t('field-requestHook-hint', { ns: 'component' })}
          value={component?.lifecycle?.hooks.request}
          type={'url'}
          onSave={(value) =>
            updateComponent({
              componentType,
              lifecycle: { hooks: { request: value } },
            })
          }
        />
        <EditableTextField
          label={t('field-attachHook-label', { ns: 'component' })}
          hint={t('field-attachHook-hint', { ns: 'component' })}
          value={component?.lifecycle?.hooks?.attach}
          type={'url'}
          onSave={(value) =>
            updateComponent({
              componentType,
              lifecycle: { hooks: { attach: value } },
            })
          }
        />
        <EditableTextField
          label={t('field-updateHook-label', { ns: 'component' })}
          hint={t('field-updateHook-hint', { ns: 'component' })}
          value={component?.lifecycle?.hooks.update}
          type={'url'}
          onSave={(value) =>
            updateComponent({
              componentType,
              lifecycle: { hooks: { update: value } },
            })
          }
        />
        <EditableTextField
          label={t('field-executeHook-label', { ns: 'component' })}
          hint={t('field-executeHook-hint', { ns: 'component' })}
          value={component?.lifecycle?.hooks?.execute}
          type={'url'}
          onSave={(value) =>
            updateComponent({
              lifecycle: { hooks: { execute: value } },
            })
          }
        />
        <EditableTextField
          label={t('field-detachHook-label', { ns: 'component' })}
          hint={t('field-detachHook-hint', { ns: 'component' })}
          value={component?.lifecycle?.hooks?.detach}
          type={'url'}
          onSave={(value) =>
            updateComponent({
              componentType,
              lifecycle: { hooks: { detach: value } },
            })
          }
        />
      </Fieldset>
    </Box>
  );
}

export default ComponentLifecycle;
