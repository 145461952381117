import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getOpenApiClient } from '../services/OpenApiClient';
import {
  useCollaborationService,
  useComponentService,
  useDemonstrationService,
  useIdentityProviderService,
  useOpportunityService,
  useComponentInstanceService,
  useStatusService,
} from '../services';
import { useFlags } from 'flagsmith/react';

const ServiceContext = createContext({
  demoService: {},
  identityProviderService: {},
  componentService: {},
  collaborationService: {},
  opportunityService: {},
  componentInstanceService: {},
  statusService: {},
});

const ServiceContextProvider = ({ children }) => {
  const flags = useFlags(['component_migration']);
  const [demoService, setDemoService] = useState();
  const [identityProviderService, setIdentityProviderService] = useState();
  const [componentService, setComponentService] = useState();
  const [componentInstanceService, setComponentInstanceService] = useState();
  const [collaborationService, setCollaboriationService] = useState();
  const [opportunityService, setOpportunityService] = useState();
  const [statusService, setStatusService] = useState();
  const [loading, setLoading] = useState();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      async function loadOpenApiClient() {
        try {
          const accessToken = await getAccessTokenSilently();
          const { apiClient } = await getOpenApiClient(accessToken);
          setDemoService(useDemonstrationService(apiClient));
          setComponentService(useComponentService(apiClient));
          setIdentityProviderService(useIdentityProviderService(apiClient));
          setCollaboriationService(useCollaborationService(apiClient));
          setOpportunityService(useOpportunityService(apiClient));
          setComponentInstanceService(useComponentInstanceService(apiClient));
          setStatusService(useStatusService());
        } catch (err) {
          setDemoService({});
          setComponentService({});
          setComponentInstanceService({});
          setIdentityProviderService({});
          setCollaboriationService({});
          setOpportunityService({});
          setStatusService({});
        }
      }
      setLoading(true);
      loadOpenApiClient();
      setLoading(false);
    } else {
      setDemoService({});
      setComponentService({});
      setComponentInstanceService({});
      setIdentityProviderService({});
      setCollaboriationService({});
      setOpportunityService({});
      setStatusService({});
    }
  }, [
    isAuthenticated,
    setCollaboriationService,
    setComponentService,
    setDemoService,
    setIdentityProviderService,
    setOpportunityService,
    setComponentInstanceService,
    setStatusService,
  ]);

  return (
    <ServiceContext.Provider
      value={{
        identityProviderService,
        collaborationService,
        componentService,
        opportunityService,
        demoService,
        componentInstanceService,
        statusService,
        loading,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
export const useServiceContext = () => useContext(ServiceContext);
export default ServiceContextProvider;
