import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import StateLabel from '../components/StateLabel';
import DemoTypeLabel from '../components/DemoTypeLabel';
import ErrorMessage from '../components/ErrorMessage';
import IdpTypeGraphic from '../components/IdpTypeGraphic';
import { useNavigate, useLocation } from 'react-router';
import { useFlags } from 'flagsmith/react';
import OpportunityDetails from '../components/OpportunityDetails';
import IdpType from '../components/IdpType';
import CollaboratorList from '../components/collaboration/CollaboratorList';
import CollaborationModal from '../components/collaboration/CollaborationModal';
import { useDemoContext } from '../context';
import { DemonstrationLabel } from '../components/DemonstrationLabel';
import {
  Box,
  BreadcrumbList,
  CircularProgress,
  Button,
  Card,
  Surface,
  Dialog,
  Link,
} from '@okta/odyssey-react-mui';
import LaunchButton from '../components/ui/LaunchButton';
import flagsmith from 'flagsmith';
import Container from '../components/ui/Container/Container';
import Header from '../components/ui/Header/Header';
import Divider from '../components/ui/Divider/Divider';
import Image from '../components/ui/Image/Image';
import CardGroup from '../components/ui/CardGroup/CardGroup';
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  HomeIcon,
} from '@okta/odyssey-react-mui/icons';
import './DemoDetails.css';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { DataView } from '@okta/odyssey-react-mui/labs';
import { useServiceContext } from '../context';
import { StaticComponents } from '../components/constants';
import {
  ComponentCategoryTypeLabel,
  SummarisedText,
} from '../components/labels';
import { useTranslation } from 'react-i18next';
const DemoDetails = () => {
  let params = useParams();
  const demoContext = useDemoContext();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [demo, setDemo] = useState();
  const [idp, setIDP] = useState();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const { demoService, identityProviderService } = useServiceContext();
  const { t } = useTranslation(['core', 'component', 'demo', 'idp']);

  const flags = useFlags([
    'resources',
    'insights_static_resource',
    'mobile_experience_portal_beta_static_app',
    'one_click_guide_page',
    'collaboration',
  ]);

  const getDemo = useCallback(async () => {
    setError();
    if (isAuthenticated && demoService) {
      try {
        let demo = await demoService.getDemo(params.demoName);
        demo.app_instances.sort((a, b) => a.label.localeCompare(b.label));
        demo.resource_instances.sort((a, b) => a.label.localeCompare(b.label));
        setDemo(demo);
      } catch (error) {
        setError(error);
      }
    } else {
      setDemo();
    }
  }, [isAuthenticated, setDemo, params.demoName, demoService]);

  const getIdp = useCallback(async () => {
    if (isAuthenticated) {
      if (demo) {
        try {
          let identityProvider =
            await identityProviderService.getIdentityProvider(demo.idp_id);
          setIDP(identityProvider);
        } catch (err) {
          setError(err);
        }
      }
    } else {
      setDemo();
    }
  }, [demo, isAuthenticated, setIDP, identityProviderService]);

  useEffect(() => {
    if (state?.idp) {
      setIDP(state.idp);
    }
    if (state?.demo) {
      setDemo(state.demo);
    }
  }, [state]);

  useEffect(() => {
    if (!idp) {
      getIdp();
    }
    if (idp && idp.state !== 'active') {
      const handle = setTimeout(() => getIdp(), 5000);
      return () => {
        clearTimeout(handle);
      };
    }
  }, [idp, getIdp]);

  useEffect(() => {
    if (!demo) {
      getDemo();
    }
    if (demo && demo.state !== 'active' && demo.state !== 'error') {
      var handle = setTimeout(() => getDemo(), 5000);
      return () => {
        clearTimeout(handle);
      };
    }
  }, [demo, getDemo]);

  async function deleteDemo() {
    setWaiting(true);

    try {
      await demoService.deleteDemo(demo.name);
      demoContext.refreshContext();
      navigate('/');
    } catch (error) {
      setError(error);
      setWaiting(false);
    }
  }

  async function linkOpportunity(oppId) {
    setWaiting(true);

    try {
      let demo = demoService.linkDemoToOpportunity(demo.name, oppId);
      demo.app_instances.sort((a, b) => a.label.localeCompare(b.label));
      demo.resource_instances.sort((a, b) => a.label.localeCompare(b.label));
      setDemo(demo);
      setWaiting(false);
    } catch (err) {
      setWaiting(false);
    }
  }

  function handleOnClick(path) {
    navigate(path);
  }

  const dataViewGetData = ({ search, filters }) => {
    let demoCopy = structuredClone(demo);
    let data = [...demoCopy.resource_instances, ...demoCopy.app_instances];
    if (
      idp?.type === 'customer-identity' &&
      flags.insights_static_resource.enabled
    ) {
      data.push(StaticComponents.insights);
    }

    if (flags.mobile_experience_portal_beta_static_app.enabled) {
      data.push(StaticComponents.mobileExperience);
    }

    if (search && search.length > 0) {
      const lowerCaseSearch = search.toLowerCase();

      data = data.filter((row) =>
        Object.values(row).some((value) => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(lowerCaseSearch);
          }
          return false;
        })
      );
    }

    function filterData(data, filters) {
      return data.filter((item) => {
        return filters.every((filter) => {
          if (!filter.value || filter.value.length === 0) {
            return true;
          }
          const selectedValues = filter.value.map((v) => v.value);
          const itemValue = item[filter.id];
          return selectedValues.includes(itemValue);
        });
      });
    }
    if (filters && data.length) {
      return filterData(data, filters);
    }
    return data.sort((a, b) => a.label.localeCompare(b.label));
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <BreadcrumbList>
          <RouterLink to="/">
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </RouterLink>
          <RouterLink to="/demos">
            {t('demo-list-label', { ns: 'demo' })}
          </RouterLink>
          <i href="">{demo ? demo.label : params.demoName}</i>
        </BreadcrumbList>
      </div>
      <div
        className="componentHeader"
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Header>
          {demo && idp ? (
            <div className="componentHeader">
              <DemonstrationLabel demo={demo} />
              <CollaboratorList
                entity={demo}
                setOpen={setOpen}
                includeManage={flags.collaboration?.enabled}
              />
              <CollaborationModal
                entity={demo}
                open={open}
                setOpen={setOpen}
                entityType="demonstration"
                function_reloaddata={getDemo}
              />
            </div>
          ) : (
            <>
              <Skeleton variant="text" width={250} height={80} />
              <Skeleton variant="text" width={250} height={50} />
              <Skeleton variant="circular" width={40} height={40} />
            </>
          )}
        </Header>
        <div
          style={{
            marginLeft: 'auto',
            alignItems: 'top',
            paddingTop: '1.5rem',
          }}
        >
          {waiting && <CircularProgress active inline />}
          {demo && idp && (
            <>
              {flagsmith.getTrait('connection') === 'employee' &&
                demo.type !== 'opportunity' && (
                  <Button
                    onClick={async () => {
                      setWaiting(true);

                      try {
                        let demoData =
                          demoService.convertEnablementDemoToOpportunity(
                            demo.name
                          );
                        setDemo(demoData);
                        demoContext.refreshContext();
                        setWaiting(false);
                      } catch (err) {
                        setError(err);
                        setWaiting(false);
                      }
                    }}
                    variant="primary"
                    label={t('button-link-opp-label', { ns: 'demo' })}
                    isDisabled={waiting || demo.state === 'queued'}
                  />
                )}
              <Button
                label={t('button-delete-label', { ns: 'core' })}
                variant="danger"
                onClick={() => setDeleteModalOpen(true)}
                isDisabled={waiting || demo.state === 'queued'}
              />
              <Dialog
                onClose={() => setDeleteModalOpen(false)}
                title={t('dialog-deleteDemo-title', {
                  ns: 'demo',
                  demoName: demo.name,
                })}
                onOpen={() => setDeleteModalOpen(true)}
                isOpen={deleteModalOpen}
                primaryCallToActionComponent={
                  <Button
                    variant="danger"
                    onClick={() => {
                      setDeleteModalOpen(false);
                      deleteDemo();
                    }}
                    label={t('button-delete-label', { ns: 'core' })}
                    startIcon={<CheckIcon />}
                  ></Button>
                }
                secondaryCallToActionComponent={
                  <Button
                    onClick={() => setDeleteModalOpen(false)}
                    label={t('button-cancel-label', { ns: 'core' })}
                    variant="secondary"
                    startIcon={<CloseIcon />}
                  ></Button>
                }
              >
                {t('dialog-deleteDemo-description', { ns: 'demo' })}
              </Dialog>
            </>
          )}
        </div>
      </div>
      <Surface>
        {demo && idp && (
          <Box>
            {demo.type === 'opportunity' ? (
              <OpportunityDetails
                linkedOpportunities={demo.linked_opportunities}
                function_linkOpportunity={linkOpportunity}
                allowEdit={!waiting}
              />
            ) : (
              <DemoTypeLabel type={demo.type} />
            )}

            <Divider className="noTopMargin" />
          </Box>
        )}
        {error && <ErrorMessage error={error} retryAction={getDemo} />}
        {demo ? (
          <Container>
            <Container>
              <Header as="h2">{t('header-idp-title', { ns: 'idp' })}</Header>
              <Container>
                {idp ? (
                  <CardGroup itemsPerRow={3}>
                    <Card
                      title={<IdpType idp={idp} />}
                      description={
                        <>
                          <IdpTypeGraphic type={idp.type} />
                          <StateLabel state={idp.state} />
                          {idp.type === 'customer-identity' &&
                            idp.invite_link && (
                              <div>
                                {idp.meta_data.created_by === user.email ? (
                                  <span>
                                    {t('invite-cic-description', {
                                      ns: 'idp',
                                    })}
                                  </span>
                                ) : (
                                  <span>
                                    {t('invite-okta-description', {
                                      ns: 'idp',
                                    })}
                                  </span>
                                )}
                              </div>
                            )}
                        </>
                      }
                      button={
                        <div className="buttonContainerFooter">
                          <LaunchButton
                            url={idp.dashboard}
                            isDisabled={idp.state !== 'active'}
                            label={t('button-launch-label', { ns: 'core' })}
                          />
                          {idp.type === 'customer-identity' &&
                            idp.invite_link &&
                            idp.meta_data.created_by === user.email && (
                              <Button
                                className="branded"
                                onClick={() => window.open(idp.invite_link)}
                                label={t('button-accept-invite-label', {
                                  ns: 'idp',
                                })}
                              ></Button>
                            )}
                        </div>
                      }
                    ></Card>
                  </CardGroup>
                ) : (
                  <div className="skeletonContainer">
                    <Stack spacing={1}>
                      <Skeleton variant="rounded" width={300} height={250} />
                    </Stack>
                  </div>
                )}
              </Container>
              <div className="titleAndButtonContainer">
                <Header as="h2">
                  {t('header-attached-title', { ns: 'component' })}
                </Header>
                <Button
                  isDisabled={demo.state !== 'active'}
                  className="createActionButton branded"
                  label={t('button-attach-label', { ns: 'component' })}
                  startIcon={<AddIcon />}
                  labelPosition="left"
                  onClick={() => handleOnClick(`/demo/${demo.name}/components`)}
                />
              </div>

              {demo && (
                <div style={{ marginTop: '1rem' }}>
                  <DataView
                    getData={dataViewGetData}
                    availableLayouts={['grid']}
                    hasSearch
                    hasFilters
                    filters={[
                      {
                        id: 'componentType',
                        label: t('component-type-label', { ns: 'component' }),
                        variant: 'multi-select',
                        options: [
                          {
                            label: t('app-type', { ns: 'component' }),
                            value: 'app',
                          },
                          {
                            label: t('resource-type', { ns: 'component' }),
                            value: 'resource',
                          },
                        ],
                      },
                    ]}
                    cardLayoutOptions={{
                      itemProps: (row) => ({
                        maxGridColumns: 4,

                        title: (
                          <div>
                            {row.label}
                            {row.logo && (
                              <div>
                                <Image
                                  floated="right"
                                  style={{ float: 'right' }}
                                  size="tiny"
                                  src={row.logo}
                                />
                              </div>
                            )}
                          </div>
                        ),
                        children: (
                          <div>
                            <>
                              {row.description && (
                                <div className="">
                                  <SummarisedText value={row.description} />
                                </div>
                              )}
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  gap: '0.5rem',
                                }}
                              >
                                <StateLabel state={row.state} />
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                  }}
                                >
                                  <ComponentCategoryTypeLabel
                                    type={row.componentType}
                                  />
                                </div>
                              </div>
                              <span className="ComponentMetaRow">
                                {row.documentationLink && (
                                  <Link
                                    href={row.documentationLink}
                                    target="_blank"
                                  >
                                    {t('link-documentation-label', {
                                      ns: 'component',
                                    })}
                                  </Link>
                                )}
                                {row.video && (
                                  <Link href={row.video} target="_blank">
                                    {t('link-video-label', {
                                      ns: 'component',
                                    })}
                                  </Link>
                                )}
                                {row.supportLink && (
                                  <Link target="_blank" href={row.supportLink}>
                                    {t('link-support-label', {
                                      ns: 'component',
                                    })}
                                  </Link>
                                )}
                              </span>
                              <div className="buttonContainerFooter">
                                {row.launchUrl ? (
                                  <>
                                    {row.deploymentData?.launchUriIsFlows ? (
                                      <LaunchButton
                                        className="cta"
                                        url={row.launchUrl}
                                        label={t('button-flows-label', {
                                          ns: 'component',
                                        })}
                                      />
                                    ) : row.deploymentData
                                        ?.launchUriIsDownload ? (
                                      <LaunchButton
                                        className="cta"
                                        url={row.launchUrl}
                                        label={t('button-download-label', {
                                          ns: 'component',
                                        })}
                                      />
                                    ) : (
                                      <LaunchButton url={row.launchUrl} />
                                    )}
                                  </>
                                ) : (
                                  row.link && <LaunchButton url={row.link} />
                                )}

                                {row.componentId && (
                                  <Button
                                    className=""
                                    label={t('button-manage-label', {
                                      ns: 'core',
                                    })}
                                    onClick={() =>
                                      handleOnClick(
                                        `/demo/${demo.name}/components/${row.componentId}`
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </>
                          </div>
                        ),
                      }),
                    }}
                  />
                </div>
              )}
            </Container>
          </Container>
        ) : (
          !error && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              <div className="skeletonContainer">
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={300} height={250} />
                </Stack>
              </div>
              <div className="skeletonContainer">
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={45} height={40} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={400} height={40} />
                </Stack>
              </div>
              <div className="skeletonContainer">
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={250} />
                </Stack>
              </div>
            </div>
          )
        )}
      </Surface>
    </div>
  );
};
export default DemoDetails;
