import { useTranslation } from 'react-i18next';

export const ComponentTypeOptions = () => {
  const { t } = useTranslation(['core', 'component', 'demo']);
  return [
    {
      key: 'app',
      text: t('app-type', { ns: 'component' }),
      value: 'app',
    },
    {
      key: 'resource',
      text: t('resource-type', { ns: 'component' }),
      value: 'resource',
    },
  ];
};
