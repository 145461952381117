import { Autocomplete, Box } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';
import { EditableTextField } from '../fields';
import { ComponentTagOptions } from '../constants';

function GeneralComponentDetails({ component, updateComponent }) {
  const { t } = useTranslation(['core', 'component', 'demo']);
  const { componentType } = component;

  function isOptionalEqualToValue(tagOption, value) {
    return tagOption.id === value.id;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <EditableTextField
        label={t('field-name-label', { ns: 'component' })}
        hint={t('field-name-hint', { ns: 'component' })}
        placeholder={t('field-name-placeholder', { ns: 'component' })}
        value={component?.general?.name}
        onSave={(value) =>
          updateComponent({
            componentType,
            general: { name: value },
          })
        }
      />
      <EditableTextField
        label={t('field-description-label', { ns: 'component' })}
        hint={t('field-description-hint', { ns: 'component' })}
        placeholder={t('field-description-placeholder', { ns: 'component' })}
        value={component?.general?.description}
        multiline={true}
        onSave={(value) =>
          updateComponent({
            componentType,
            general: { description: value },
          })
        }
      />
      {/* <Tag icon={<UserIcon />} label={component.general.category} /> */}
      <EditableTextField
        label={t('field-version-label', { ns: 'component' })}
        hint={t('field-version-hint', { ns: 'component' })}
        value={component?.general?.version}
        onSave={(value) => {
          updateComponent({
            componentType,
            general: { version: value },
          });
        }}
      />
      <EditableTextField
        label={t('field-supportLink-label', { ns: 'component' })}
        hint={t('field-supportLink-hint', { ns: 'component' })}
        placeholder={t('field-supportLink-placeholder', { ns: 'component' })}
        value={component?.general?.supportUri}
        type={'url'}
        onSave={(value) =>
          updateComponent({
            componentType,
            general: { supportUri: value },
          })
        }
      />
      <EditableTextField
        label={t('field-documentationLink-label', { ns: 'component' })}
        hint={t('field-documentationLink-hint', { ns: 'component' })}
        placeholder={t('field-documentationLink-placeholder', {
          ns: 'component',
        })}
        value={component?.general?.documentationUri}
        type={'url'}
        onSave={(value) =>
          updateComponent({
            componentType,
            general: { documentationUri: value },
          })
        }
      />
      <EditableTextField
        label={t('field-videoLink-label', { ns: 'component' })}
        hint={t('field-videoLink-hint', { ns: 'component' })}
        placeholder={t('field-videoLink-placeholder', { ns: 'component' })}
        value={component?.general?.videoUri}
        type={'url'}
        onSave={(value) =>
          updateComponent({
            componentType,
            general: { videoUri: value },
          })
        }
      />
      <EditableTextField
        label={t('field-logoLink-label', { ns: 'component' })}
        hint={t('field-logoLink-hint', { ns: 'component' })}
        placeholder={t('field-logoLink-placeholder', { ns: 'component' })}
        value={component?.general?.logoUri}
        type={'url'}
        onSave={(value) =>
          updateComponent({
            componentType,
            general: { logoUri: value },
          })
        }
      />
      <Autocomplete
        hasMultipleChoices
        isOptional
        hint={t('field-tags-hint', { ns: 'component' })}
        label={t('field-tags-label', { ns: 'component' })}
        onChange={(value, tags) => {
          updateComponent({
            componentType,
            general: { tags: tags.map((tag) => tag.value) },
          });
        }}
        getIsOptionEqualToValue={isOptionalEqualToValue}
        options={ComponentTagOptions()}
        defaultValue={component?.general?.tags || []}
      />
    </Box>
  );
}

export default GeneralComponentDetails;
