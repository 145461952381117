import {
  PasswordField,
  Switch,
  TextField,
  Typography,
} from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';

export default function useOdysseyRJSF() {
  const { t } = useTranslation(['managed', 'component']);

  const uiSchema = {
    'ui:submitButtonOptions': { norender: true },
    'ui:title': t('header-instanceSettings', { ns: 'component' }),
    'ui:hideError': true,
    'ui:emptyValue': undefined,
  };

  function errorTransformer(errors, uiSchema) {
    return errors.map((error) => {
      error.message = t(`error-${error.name}-description`, {
        ns: 'component',
        ...error.params,
      });
      return error;
    });
  }

  function DescriptionFieldTemplate() {
    return;
  }

  function ErrorListTemplate(props) {
    return;
  }

  function TitleFieldTemplate(props) {
    const { title } = props;
    return <Typography variant="legend">{title}</Typography>;
  }

  const CustomCheckbox = function (props) {
    if (props.id.startsWith('_')) {
      return null;
    } else {
      return (
        <Switch
          name={props.id}
          onChange={(event) => {
            props.onChange(!event.checked);
          }}
          errorMessageList={props.rawErrors}
          label={t(props.label)}
          isChecked={props.value}
          value={props.value}
          hint={t(props.schema.description)}
          isReadOnly={props.readonly}
          isOptional={!props.required}
          isDisabled={/^true$/i.test(props.disabled)}
        />
      );
    }
  };

  const CustomEmail = function (props) {
    if (props.id.startsWith('_')) {
      return null;
    } else {
      return (
        <TextField
          name={props.id}
          type="email"
          onChange={(event) => props.onChange(event.target.value)}
          errorMessageList={props.rawErrors}
          label={t(props.label)}
          hint={t(props.schema.description)}
          placeholder={t(props.placeholder)}
          isReadOnly={props.readonly}
          isOptional={!props.required}
          isDisabled={/^true$/i.test(props.disabled)}
          defaultValue={props.value}
        />
      );
    }
  };

  const CustomPassword = function (props) {
    if (props.id.startsWith('_')) {
      return null;
    } else {
      return (
        <PasswordField
          name={props.id}
          hasShowPassword
          onChange={(event) => props.onChange(event.target.value)}
          errorMessageList={props.rawErrors}
          label={t(props.label)}
          hint={t(props.schema.description)}
          placeholder={t(props.placeholder)}
          isReadOnly={props.readonly}
          isOptional={!props.required}
          isDisabled={/^true$/i.test(props.disabled)}
          defaultValue={props.value}
        />
      );
    }
  };

  const CustomText = function (props) {
    if (props.id.startsWith('_')) {
      return null;
    } else {
      return (
        <TextField
          name={props.id}
          type="text"
          onChange={(event) => props.onChange(event.target.value)}
          errorMessageList={props.rawErrors}
          label={t(props.label)}
          hint={t(props.schema.description)}
          placeholder={t(props.placeholder)}
          isReadOnly={props.readonly}
          isOptional={!props.required}
          isDisabled={/^true$/i.test(props.disabled)}
          defaultValue={props.value}
        />
      );
    }
  };

  const CustomTextArea = function (props) {
    if (props.id.startsWith('_')) {
      return null;
    } else {
      return (
        <TextField
          name={props.id}
          type="text"
          onChange={(event) => props.onChange(event.target.value)}
          errorMessageList={props.rawErrors}
          multiline={true}
          label={t(props.label)}
          hint={t(props.hint)}
          placeholder={t(props.placeholder)}
          isReadOnly={props.readonly}
          isOptional={!props.required}
          isDisabled={/^true$/i.test(props.disabled)}
          value={props.value}
        />
      );
    }
  };

  const CustomURL = function (props) {
    if (props.id.startsWith('_')) {
      return null;
    } else {
      return (
        <TextField
          name={props.id}
          type="url"
          onChange={(event) => props.onChange(event.target.value)}
          errorMessageList={props.rawErrors}
          label={t(props.label)}
          hint={t(props.hint)}
          placeholder={t(props.placeholder)}
          isReadOnly={props.readonly}
          isOptional={!props.required}
          isDisabled={/^true$/i.test(props.disabled)}
          value={props.value}
        />
      );
    }
  };

  const widgets = {
    CheckboxWidget: CustomCheckbox,
    // CheckboxesWidget:
    // DateTimeWidget
    EmailWidget: CustomEmail,
    // FileWidget
    PasswordWidget: CustomPassword,
    // RadioWidget
    // SelectWidget
    TextareaWidget: CustomTextArea,
    TextWidget: CustomText,
    URLWidget: CustomURL,
  };

  const template = {
    DescriptionFieldTemplate,
    TitleFieldTemplate,
    ErrorListTemplate,
  };

  return { widgets, uiSchema, template, errorTransformer };
}
