import { Banner } from '@okta/odyssey-react-mui';
import React, { useEffect, useState } from 'react';
import config from '../Config';
import { useServiceContext } from '../context';

export default function StatusBanner() {
  const [status, setStatus] = useState();
  const { statusService } = useServiceContext();

  useEffect(() => {
    //statusService.getServiceStatus().then((status) => setStatus(status));
  }, [statusService]);

  if (status && status?.status_code !== 100) {
    return (
      <Banner
        severity={status.status_code >= 400 ? 'error' : 'warning'}
        text={status.status}
        linkUrl="https://status.demo.okta.com"
        linkText="See service status"
      ></Banner>
    );
  } else if (config.resourceServer.environmentName !== 'Production') {
    return (
      <Banner
        severity="info"
        text={`${config.resourceServer.environmentName} Environment`}
      ></Banner>
    );
  }
}
