import { useTranslation } from 'react-i18next';

export const ComponentTagOptions = () => {
  const { t } = useTranslation(['core', 'component', 'demo']);
  return [
    {
      label: t('tag-workforce', { ns: 'component' }),
      value: 'workforce',
      id: 'workforce',
    },
    {
      label: t('tag-ciam', { ns: 'component' }),
      value: 'ciam',
      id: 'ciam',
    },
    {
      label: t('tag-hri', { ns: 'component' }),
      value: 'hri',
      id: 'hri',
    },
    {
      label: t('tag-fga', { ns: 'component' }),
      value: 'fga',
      id: 'fga',
    },
    {
      label: t('tag-opa', { ns: 'component' }),
      value: 'opa',
      id: 'opa',
    },
    {
      label: t('tag-oda', { ns: 'component' }),
      value: 'oda',
      id: 'oda',
    },
    {
      label: t('tag-itp', { ns: 'component' }),
      value: 'itp',
      id: 'itp',
    },
    {
      label: t('tag-oig', { ns: 'component' }),
      value: 'oig',
      id: 'oig',
    },
    {
      label: t('tag-partner-tech', { ns: 'component' }),
      value: 'partner-tech',
      id: 'partner-tech',
    },
    {
      label: t('tag-forms', { ns: 'component' }),
      value: 'forms',
      id: 'forms',
    },
    {
      label: t('tag-acul', { ns: 'component' }),
      value: 'acul',
      id: 'acul',
    },
    {
      label: t('tag-policy', { ns: 'component' }),
      value: 'policy',
      id: 'policy',
    },
    {
      label: t('tag-ui', { ns: 'component' }),
      value: 'ui',
      id: 'ui',
    },
    {
      label: t('tag-itsm', { ns: 'component' }),
      value: 'itsm',
      id: 'itsm',
    },
    {
      label: t('tag-workflows', { ns: 'component' }),
      value: 'workflows',
      id: 'workflows',
    },
    {
      label: t('tag-enablement', { ns: 'component' }),
      value: 'enablement',
      id: 'enablement',
    },
    {
      label: t('tag-demo-util', { ns: 'component' }),
      value: 'demo-util',
      id: 'demo-util',
    },
    {
      label: t('tag-branding', { ns: 'component' }),
      value: 'branding',
      id: 'branding',
    },
    {
      label: t('tag-vertical', { ns: 'component' }),
      value: 'vertical',
      id: 'vertical',
    },
    {
      label: t('tag-flow-pack', { ns: 'component' }),
      value: 'flow-pack',
      id: 'flow-pack',
    },
    {
      label: t('tag-ud', { ns: 'component' }),
      value: 'ud',
      id: 'ud',
    },
    {
      label: t('tag-enterprise-conn', { ns: 'component' }),
      value: 'enterprise-conn',
      id: 'enterprise-conn',
    },
    {
      label: t('tag-sso', { ns: 'component' }),
      value: 'sso',
      id: 'sso',
    },
    {
      label: t('tag-amfa', { ns: 'component' }),
      value: 'amfa',
      id: 'amfa',
    },
    {
      label: t('tag-m2m', { ns: 'component' }),
      value: 'm2m',
      id: 'm2m',
    },
    {
      label: t('tag-lcm', { ns: 'component' }),
      value: 'lcm',
      id: 'lcm',
    },
    {
      label: t('tag-access-gateway', { ns: 'component' }),
      value: 'access-gateway',
      id: 'access-gateway',
    },
    {
      label: t('tag-ama', { ns: 'component' }),
      value: 'ama',
      id: 'ama',
    },
  ];
};
