import flagsmith from 'flagsmith';
import { createContext, useContext } from 'react';

const UserPreferenceContext = createContext({
  rewriteLaunchUrl: () => {},
  awsAccountId: '000000000000',
  awsRegion: 'us-east-1',
  favoriteComponents: [],
});

const UserPreferenceContextProvider = ({ children }) => {
  const rdpPattern =
    /(?<protocol>rdp:\/\/)(full%20address:s:)(?<ip>[0-9.:]+)(&.*)/;

  function rewriteLaunchUrl(url) {
    if (flagsmith.getTrait('rdp_preference') === 'tsx') {
      var isRDP = rdpPattern.exec(url);
      if (isRDP) {
        return 'rtsx://' + isRDP.groups['protocol'] + isRDP.groups['ip'];
      }
    }
    return url;
  }

  function awsAccountId() {
    let accountId = flagsmith.getTrait('aws_account_id').toString() || '';
    //this is needed as flagsmith forces all numberic traits to number and trims leading zeros
    if (accountId.length < 12) {
      accountId = String(accountId).padStart(12, '0');
    }
    return accountId;
  }

  function awsRegion() {
    return flagsmith.getTrait('aws_preferred_region');
  }

  function favoriteComponents() {
    return flagsmith.getTrait('favouriteComponents') || [];
  }

  return (
    <UserPreferenceContext.Provider
      value={{ rewriteLaunchUrl, awsAccountId, awsRegion, favoriteComponents }}
    >
      {children}
    </UserPreferenceContext.Provider>
  );
};

export const useUserPreferenceContext = () => useContext(UserPreferenceContext);
export default UserPreferenceContextProvider;
