import { Button, Dialog, TextField } from '@okta/odyssey-react-mui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSchemaValidation } from '../../util';

const ImportSettingsModal = ({
  componentId,
  schema,
  isOpen,
  setOpen,
  onImport,
  value,
}) => {
  const { t } = useTranslation(['core', 'component', 'demos']);
  const { validateData } = useSchemaValidation();
  const [settingsToImport, setSettingsToImport] = useState();
  const [importError, setImportError] = useState();

  const handleChange = (event) => {
    let importSettings;
    try {
      importSettings = JSON.parse(event.target.value);
    } catch (e) {
      setImportError(
        t('importSettings-error-invalid-json', { ns: 'component' })
      );
      return;
    }
    if (
      !importSettings ||
      !importSettings.componentId ||
      !importSettings.settings
    ) {
      setImportError(
        t('importSettings-error-invalid-payload', { ns: 'component' })
      );
      return;
    }
    if (importSettings.componentId !== componentId) {
      setImportError(
        t('importSettings-error-wrong-component', { ns: 'component' })
      );
      return;
    }
    try {
      const isValid = validateData(schema);
      if (!isValid(importSettings.settings)) {
        setImportError(
          t('importSettings-error-schema-validation', { ns: 'component' })
        );
        return;
      }
      setImportError();
      setSettingsToImport(importSettings);
    } catch (e) {
      setImportError(
        t('importSettings-error-unknown-schema', { ns: 'component' })
      );
      return;
    }
  };

  return (
    <Dialog
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      width={500}
      primaryCallToActionComponent={
        <Button
          label={t('button-import-label', { ns: 'core' })}
          onClick={() => {
            onImport(settingsToImport);
            setImportError();
            setSettingsToImport();
            setOpen(false);
          }}
          variant="primary"
        />
      }
      tertiaryCallToActionComponent={
        <Button
          label={t('button-cancel-label', { ns: 'core' })}
          onClick={() => {
            setSettingsToImport();
            setImportError();
            setOpen(false);
          }}
          variant="floating"
        />
      }
      title={t('importSettings-title', { ns: 'component' })}
    >
      <span>{t('importSettings-hint', { ns: 'component' })}</span>
      <TextField
        errorMessage={t(importError, { ns: 'components' })}
        isMultiline
        value={value}
        onChange={handleChange}
      />
    </Dialog>
  );
};

export default ImportSettingsModal;
