import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'flagsmith/react';
import Header from '../components/ui/Header/Header';
import Image from '../components/ui/Image/Image';
import {
  Callout,
  Button,
  Card,
  EmptyState,
  BreadcrumbList,
  Typography,
} from '@okta/odyssey-react-mui';
import { AddIcon, ExternalLinkIcon } from '@okta/odyssey-react-mui/icons';
import CardGroup from '../components/ui/CardGroup/CardGroup';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { useServiceContext } from '../context';
import {
  ComponentCategoryTypeLabel,
  SummarisedText,
} from '../components/labels';
import { Link as RouterLink } from 'react-router-dom';
import { HomeIcon } from '@okta/odyssey-react-mui/icons';

const Components = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['core', 'component', 'demo']);
  const [components, setComponents] = useState();
  const [error, setError] = useState(null);
  const { user, isAuthenticated } = useAuth0();
  const flags = useFlags(['component_limits']);
  const { componentService } = useServiceContext();

  const loadOwnComponents = useCallback(async () => {
    setError();
    if (isAuthenticated) {
      try {
        const components = await componentService.listOwnedComponents(user);
        components.sort((a, b) => a.general.name.localeCompare(b.name));
        setComponents(components);
      } catch (error) {
        setError(error);
      }
    } else {
      setComponents([]);
    }
  }, [isAuthenticated, setComponents, user.email, componentService]);

  useEffect(() => {
    if (isAuthenticated) {
      loadOwnComponents();
    } else {
      setComponents([]);
    }
  }, [loadOwnComponents, isAuthenticated, setComponents, componentService]);

  function handleOnClick(path) {
    navigate(path);
  }

  return (
    <div>
      <div>
        <BreadcrumbList>
          <RouterLink to="/">
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </RouterLink>
          <i href="">{t('components-list-label', { ns: 'component' })}</i>
        </BreadcrumbList>
      </div>
      <div
        className="componentHeader"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <div style={{ marginRight: '2rem' }}>
          <Header>
            <Typography variant="h1">
              {t('ownComponents-title', { ns: 'component' })}
            </Typography>
            <Typography variant="subordinate">
              {t('ownComponents-description', { ns: 'component' })}
            </Typography>
          </Header>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          {components ? (
            <Button
              className="createActionButton branded"
              label={t('button-createComponent-label', { ns: 'component' })}
              startIcon={<AddIcon />}
              variant="primary"
              onClick={() => handleOnClick('/component')}
              isDisabled={
                flags.component_limits.enabled &&
                components.length >= flags.component_limits.value
              }
            />
          ) : (
            <Skeleton variant="rounded" width={150} height={50} />
          )}
        </div>
      </div>
      <div>
        {components ? (
          <div className="mainContainer">
            {flags.component_limits.enabled &&
              components.length >= flags.component_limits.value && (
                <Callout
                  severity="error"
                  text={t('createComponent-limitReached', { ns: 'component' })}
                />
              )}
            {components.length !== 0 ? (
              <CardGroup className="hoverDarkerCard">
                {components.map((component) => (
                  <Card
                    key={component.componentId}
                    onClick={() =>
                      handleOnClick(`/components/${component.componentId}`)
                    }
                    title={component.general.name}
                    image={
                      <Image
                        src={
                          component.general.logoUri
                            ? component.general.logoUri
                            : 'https://cdn.demo.okta.com/images/identicons-set-v2/gray/svg/icon-applications.svg'
                        }
                      />
                    }
                    description={
                      <SummarisedText value={component.general.description} />
                    }
                    overline={
                      <ComponentCategoryTypeLabel
                        category={component.general.category}
                        type={component.componentType}
                      />
                    }
                  ></Card>
                ))}
              </CardGroup>
            ) : (
              <EmptyState
                PrimaryCallToActionComponent={
                  <Button
                    label={t('component-create-button', { ns: 'component' })}
                    variant="primary"
                  />
                }
                SecondaryCallToActionComponent={
                  <Button
                    label={t('button-api-docs', { ns: 'core' })}
                    startIcon={<ExternalLinkIcon />}
                    onClick={() => window.open('https://docs.demo.okta.com/')}
                    variant="secondary"
                  />
                }
                description={t('component-description', { ns: 'component' })}
                heading={t('component-get-started', { ns: 'component' })}
              />
            )}
          </div>
        ) : error ? (
          <ErrorMessage error={error} retryAction={loadOwnComponents} />
        ) : (
          !error && (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <div className="skeletonContainer">
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={250} height={210} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={250} height={210} />
                </Stack>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
export default Components;
