import { Box, Fieldset, Switch } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ComponentList from '../fields/ComponentList';

function ComponentDependencies({
  component,
  updateComponent,
  loadCompatibleComponents,
}) {
  const { t } = useTranslation(['core', 'component', 'demo']);
  const [idpDependencies, setIdpDependencies] = useState(
    component?.dependsOn?.idp?.types
  );
  const [demoDependencies, setDemoDependencies] = useState(
    component?.dependsOn?.demo?.types || []
  );
  const [attachedComponentDependencies, setAttachedComponentDependencies] =
    useState(component?.dependsOn?.components?.attached || []);
  const [unattachedComponentDependencies, setUnattachedComponentDependencies] =
    useState(component?.dependsOn?.components?.unattached || []);

  const [loading, setLoading] = useState(false);
  // const [compatibleComponents, setCompatibleComponents] = useState([]);
  const { componentType } = component;

  useEffect(() => {
    const updateAndFetch = async () => {
      setLoading(true);
      await updateComponent({
        componentType,
        dependsOn: {
          idp: {
            types: idpDependencies,
          },
          demo: {
            types: demoDependencies,
          },
          components: {
            attached: attachedComponentDependencies,
            unattached: unattachedComponentDependencies,
          },
        },
      });
      // const compatibleComponents =
      //   await loadCompatibleComponents(idpDependencies);
      // setCompatibleComponents(compatibleComponents);
      setLoading(false);
    };
    updateAndFetch();
  }, [
    idpDependencies,
    demoDependencies,
    attachedComponentDependencies,
    unattachedComponentDependencies,
  ]);

  function handleChange(event) {
    if (event.target === 'require-attached') {
      setAttachedComponentDependencies(event.value);
      return;
    }
    if (event.target === 'require-unattached') {
      setUnattachedComponentDependencies(event.value);
      return;
    }
    switch (event.value) {
      case 'workforce-identity':
      case 'customer-identity':
      case 'okta-customer-identity':
        if (event.checked) {
          setIdpDependencies((prevState) => {
            let set = new Set(prevState);
            set.add(event.value);
            return Array.from(set);
          });
        } else {
          setIdpDependencies((prevState) => {
            let set = new Set(prevState);
            set.delete(event.value);
            return Array.from(set);
          });
        }
        break;
      case 'opportunity':
      case 'enablement':
      case 'labs':
        if (event.checked) {
          setDemoDependencies((prevState) => {
            let set = new Set(prevState);
            set.add(event.value);
            return Array.from(set);
          });
        } else {
          setDemoDependencies((prevState) => {
            let set = new Set(prevState);
            set.delete(event.value);
            return Array.from(set);
          });
        }
        break;
      default:
        break;
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Fieldset
        description={t('fieldset-idpDependency-hint', {
          ns: 'component',
        })}
        legend={t('fieldset-idpDependency-label', { ns: 'component' })}
        isRequired={true}
      >
        <Switch
          label={t('field-idpDependency-workforce-label', {
            ns: 'component',
          })}
          isDisabled={loading}
          name="workforce-identity"
          value="workforce-identity"
          onChange={handleChange}
          isChecked={idpDependencies.includes('workforce-identity')}
        />
        <Switch
          label={t('field-idpDependency-customer-label', {
            ns: 'component',
          })}
          isDisabled={loading}
          name="customer-identity"
          value="customer-identity"
          onChange={handleChange}
          isChecked={idpDependencies.includes('customer-identity')}
        />
        <Switch
          label={t('field-idpDependency-okta-customer-label', {
            ns: 'component',
          })}
          name="okta-customer-identity"
          onChange={handleChange}
          value="okta-customer-identity"
          isChecked={idpDependencies.includes('okta-customer-identity')}
        />
      </Fieldset>
      <Fieldset
        description={t('fieldset-demoDependency-hint', {
          ns: 'component',
        })}
        legend={t('fieldset-demoDependency-label', { ns: 'component' })}
      >
        <Switch
          label={t('field-demoDependency-opportunity-label', {
            ns: 'component',
          })}
          name="opportunity"
          onChange={handleChange}
          value="opportunity"
          isChecked={demoDependencies.includes('opportunity')}
        />
        <Switch
          label={t('field-demoDependency-enablement-label', {
            ns: 'component',
          })}
          name="enablement"
          onChange={handleChange}
          value="enablement"
          isChecked={demoDependencies.includes('enablement')}
        />
        <Switch
          label={t('field-demoDependency-lab-label', { ns: 'component' })}
          name="lab"
          onChange={handleChange}
          value="lab"
          isChecked={demoDependencies.includes('lab')}
        />
      </Fieldset>
      {/* <Fieldset
        description={t('fieldset-componentDependency-description', {
          ns: 'component',
        })}
        legend={t('fieldset-componentDependency-legend', { ns: 'component' })}
      >
        <ComponentList
          target="require-unattached"
          components={compatibleComponents.filter(
            (c) => !unattachedComponentDependencies.includes(c.componentId)
          )}
          label={t('field-componentDependency-require-attached-label', {
            ns: 'component',
          })}
          selectionChanged={handleChange}
        />
        <ComponentList
          target="require-attached"
          components={compatibleComponents.filter(
            (c) => !attachedComponentDependencies.includes(c.componentId)
          )}
          label={t('field-componentDependency-require-not-attached-label', {
            ns: 'component',
          })}
          selectionChanged={handleChange}
        />
      </Fieldset> */}
    </Box>
  );
}

export default ComponentDependencies;
