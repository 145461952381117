import React, { useEffect, useState } from 'react';

import { useDemoContext } from '../context';
import Header from './ui/Header/Header';
import { Button, TextField } from '@okta/odyssey-react-mui';
import { EditIcon } from '@okta/odyssey-react-mui/icons';
import { useServiceContext } from '../context';
export const DemonstrationLabel = ({ demo }) => {
  const [waiting, setWaiting] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [demoLabel, setDemoLabel] = useState(demo.label);
  const demoContext = useDemoContext();
  const { demoService } = useServiceContext();

  function handleChange(event) {
    setDemoLabel(event.target.value);
  }

  return (
    <Header className="contentHeader">
      {!editMode ? (
        <>
          <span>
            {demoLabel}{' '}
            <Button
              startIcon={<EditIcon />}
              className="inlineIconControl"
              name="edit"
              size="small"
              variant="floating"
              onClick={() => {
                setEditMode(true);
              }}
            />
          </span>

          {demoLabel !== demo.name && (
            <Header.Subheader>{demo.name}</Header.Subheader>
          )}
        </>
      ) : (
        <>
          <TextField
            isDisabled={waiting}
            name="demoLabel"
            className="inlineInput"
            value={demoLabel}
            onChange={handleChange}
          >
            <input maxLength="64" />
          </TextField>
          <Button
            isDisabled={waiting}
            className="branded"
            label="Update"
            style={{ fontSize: '1rem', verticalAlign: 'text-top' }}
            onClick={async () => {
              setWaiting(true);
              await demoService.updateDemoLabel(demo.name, demoLabel);
              demoContext.refreshContext();
              setEditMode(false);
              setWaiting(false);
            }}
          ></Button>
          <Button
            isDisabled={waiting}
            label="Cancel"
            variant="secondary"
            style={{ fontSize: '1rem', verticalAlign: 'text-top' }}
            onClick={() => {
              setDemoLabel(demo.label);
              setEditMode(false);
            }}
          ></Button>
        </>
      )}
    </Header>
  );
};
